import React, { createContext, useContext, useCallback } from 'react';
import { useSnackbar } from 'notistack';

export interface ToastMessage {
  id: string;
  type: 'error' | 'success' | 'info' | 'warning' | 'default';
  title: string;
}

interface ToastContextData {
  addToast(message: Omit<ToastMessage, 'id'>): void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const addToast = useCallback(
    (message: Omit<ToastMessage, 'id'>) => {
      enqueueSnackbar(message.title, {
        variant: message.type,
        autoHideDuration: 6000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    [enqueueSnackbar],
  );

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
    </ToastContext.Provider>
  );
};

function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}

export { ToastProvider, useToast };
