import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { AxiosError } from 'axios';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import Input from '../../../../components/Input';
import { useToast } from '../../../../hooks/toast';
import getValidationErrors from '../../../../utils/getValidationErrors';
import api from '../../../../services/api';
import InputMask from '../../../../components/InputMask';

interface ICompanyData {
  id: string;
  cnpj: string;
  name: string;
  phone?: string;
  email?: string;
  nickname?: string;
  main_activity?: string;
}

interface ICompanyDataProps {
  companyData: ICompanyData;
}

const CompanyData: React.FC<ICompanyDataProps> = ({ companyData }) => {
  const formRef = React.useRef<FormHandles>(null);
  const [editMode, setEditMode] = React.useState(false);
  const { addToast } = useToast();

  const handleSubmit = React.useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          phone: Yup.string(),
          email: Yup.string(),
          nickname: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put(`/clients/${companyData.id}`, data);
        Object.assign(companyData, response.data);

        addToast({
          type: 'success',
          title: 'Endereço alterado com sucesso!',
        });
        setEditMode(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const error = err as AxiosError;
          addToast({
            type: 'error',
            title:
              error.response?.data.message ||
              'Não foi possível atualizar as informações. Tente novamente mais tarde',
          });
        }
      }
    },
    [addToast, companyData],
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={companyData}>
      <Table>
        <TableBody>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              CNPJ
            </TableCell>
            <TableCell>{companyData.cnpj}</TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Nome da Empresa
            </TableCell>
            <TableCell>{companyData.name}</TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Telefone
            </TableCell>
            <TableCell>
              {editMode && <InputMask name="phone" mask="(99) 9 9999-9999" />}
              {!editMode && companyData.phone}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              E-mail
            </TableCell>
            <TableCell>
              {editMode && <Input name="email" type="number" />}
              {!editMode && companyData.email}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Nome fantasia
            </TableCell>
            <TableCell>
              {editMode && <Input name="nickname" />}
              {!editMode && companyData.nickname}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Atividade principal
            </TableCell>
            <TableCell>{companyData.main_activity}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}
      >
        {!editMode && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setEditMode(true)}
          >
            Editar
          </Button>
        )}
        {editMode && (
          <div style={{ marginLeft: 16 }}>
            <Button color="secondary" onClick={() => setEditMode(false)}>
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: 16 }}
            >
              Salvar
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

export default CompanyData;
