import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { format, parseISO } from 'date-fns';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';

import { DriverLicenseType } from '../../../../services/dtos/types';
import Input from '../../../../components/Input';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';

// import { Container } from './styles';

interface CNHProps {
  driver_license?: DriverLicenseType;
}

const CNH: React.FC<CNHProps> = ({ driver_license }) => {
  const formRef = React.useRef<FormHandles>(null);
  const [editMode, setEditMode] = React.useState(false);
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = React.useCallback(
    async data => {
      if (!driver_license) return;
      try {
        formRef.current?.setErrors({});
        const { dispatch_date, first_dispatch_date, validity } = data;
        if (dispatch_date) data.dispatch_date = `${dispatch_date}T00:00:00`;
        if (first_dispatch_date)
          data.first_dispatch_date = `${first_dispatch_date}T00:00:00`;
        if (validity) data.validity = `${validity}T00:00:00`;

        const schema = Yup.object().shape({
          license_plate: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        await api.put(`/motorists/driver-licenses/${driver_license.id}`, data);

        addToast({
          type: 'success',
          title: 'CNH alterada com sucesso!',
        });
        setEditMode(false);
        history.go(0);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const error = err as AxiosError;
          addToast({
            type: 'error',
            title:
              error.response?.data.message ||
              'Não foi possível atualizar a CNH. Tente novamente mais tarde',
          });
        }
      }
    },
    [addToast, driver_license, history],
  );

  const formattedDates = React.useMemo(() => {
    const result = {
      dispatch_date: '',
      first_dispatch_date: '',
      validity: '',
    };
    const format_string = 'dd/MM/yyyy';
    if (driver_license?.dispatch_date) {
      const birth = parseISO(String(driver_license.dispatch_date));
      result.dispatch_date = format(birth, format_string);
    }
    if (driver_license?.first_dispatch_date) {
      const birth = parseISO(String(driver_license.first_dispatch_date));
      result.first_dispatch_date = format(birth, format_string);
    }
    if (driver_license?.validity) {
      const birth = parseISO(String(driver_license.validity));
      result.validity = format(birth, format_string);
    }
    return result;
  }, [driver_license]);

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={driver_license}>
      <Table>
        <TableBody>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Número de registro
            </TableCell>
            <TableCell>
              {editMode && <Input name="register_number" />}
              {!editMode && driver_license?.register_number}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Categoria
            </TableCell>
            <TableCell>
              {editMode && <Input name="category" />}
              {!editMode && driver_license?.category}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Data de expedição
            </TableCell>
            <TableCell>
              {editMode && <Input name="dispatch_date" type="date" />}
              {!editMode && formattedDates.dispatch_date}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Data da primeira expedição
            </TableCell>
            <TableCell>
              {editMode && <Input name="first_dispatch_date" type="date" />}
              {!editMode && formattedDates.first_dispatch_date}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Validade
            </TableCell>
            <TableCell>
              {editMode && <Input name="validity" type="date" />}
              {!editMode && formattedDates.validity}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Órgão expeditor
            </TableCell>
            <TableCell>
              {editMode && <Input name="dispatcher" />}
              {!editMode && driver_license?.dispatcher}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Número do protocolo
            </TableCell>
            <TableCell>
              {editMode && <Input name="protocol_number" />}
              {!editMode && driver_license?.protocol_number}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Número do seguro
            </TableCell>
            <TableCell>
              {editMode && <Input name="insurance_number" />}
              {!editMode && driver_license?.insurance_number}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}
      >
        {!editMode && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setEditMode(true)}
          >
            Editar
          </Button>
        )}
        {editMode && (
          <div style={{ marginLeft: 16 }}>
            <Button color="secondary" onClick={() => setEditMode(false)}>
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: 16 }}
            >
              Salvar
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

export default CNH;
