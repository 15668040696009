import React, { createContext, useCallback, useState, useContext } from 'react';

import api from '../services/api';
import { UserType } from '../services/dtos/types';
import { SignInResponseDTO } from '../services/dtos/responses';
import { SignInRequestDTO } from '../services/dtos/requests';

interface ContextData {
  user: UserType;
  signIn(data: SignInRequestDTO): Promise<void>;
  signOut(): void;
  updateUser(user: UserType): void;
}

const AuthContext = createContext<ContextData>({} as ContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<SignInResponseDTO>(() => {
    const token = localStorage.getItem('@SpotX:token');
    const user = localStorage.getItem('@SpotX:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as SignInResponseDTO;
  });

  const signIn = useCallback(async ({ email, password }: SignInRequestDTO) => {
    const response = await api.post('sessions', { email, password });

    const { token, user } = response.data;

    localStorage.setItem('@SpotX:token', token);
    localStorage.setItem('@SpotX:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@SpotX:token');
    localStorage.removeItem('@SpotX:user');

    setData({} as SignInResponseDTO);
  }, []);

  const updateUser = useCallback(
    (user: UserType) => {
      localStorage.setItem('@SpotX:user', JSON.stringify(user));
      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token],
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): ContextData {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuth must be used within an AuthProvider');

  return context;
}

export { AuthProvider, useAuth };
