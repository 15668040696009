import React from 'react';
import Button from '@material-ui/core/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import Input from '../../../../../components/Input';
import { useToast } from '../../../../../hooks/toast';
import api from '../../../../../services/api';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import Radio from '../../../../../components/Radio';
import AutocompleteGeo from '../../../../../components/AutocompleteGeo';

export interface DialogHandles {
  openDialog: () => void;
}

interface CreateRouteProps {
  motorist_id: string;
}

const CreateRoute: React.ForwardRefRenderFunction<
  DialogHandles,
  CreateRouteProps
> = ({ motorist_id }, ref) => {
  const formRef = React.useRef<FormHandles>(null);

  const [visible, setVisible] = React.useState(false);
  const [isRecurrentRoute, setIsRecurrentRoute] = React.useState(false);
  const { addToast } = useToast();
  const history = useHistory();

  const handleOpenDialog = React.useCallback(() => {
    setVisible(true);
  }, []);

  React.useImperativeHandle(ref, () => {
    return {
      openDialog: handleOpenDialog,
    };
  });

  const handleSubmit = React.useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          origin: Yup.string().required('Campo obrigatório'),
          destination: Yup.string().required('Campo obrigatório'),
          is_recurrent: Yup.boolean(),
          frequency: Yup.number().when('is_recurrent', {
            is: true,
            then: Yup.number().required('Campo obrigatório'),
            otherwise: Yup.number(),
          }),
          frequency_type: Yup.string().when('is_recurrent', {
            is: true,
            then: Yup.string().required('Escolha uma opção'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('/motorists/routes', {
          motorist_id,
          origin_id: data.origin.ibge_id,
          destination_id: data.destination.ibge_id,
          is_recurrent: data.is_recurrent,
          frequency: data.frequency,
          frequency_type: data.frequency_type,
        });

        addToast({
          type: 'success',
          title: 'Rota de preferência adicionada com sucesso!',
        });
        history.go(0);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const error = err as AxiosError;
          addToast({
            type: 'error',
            title:
              error.response?.data.message ||
              'Não foi possível adicionar a preferência de rota. Tente novamente mais tarde',
          });
        }
      }
    },
    [addToast, motorist_id, history],
  );

  const handleFinish = React.useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <Dialog open={visible} maxWidth="md" fullWidth>
      <DialogTitle>Adicionar rota de preferência</DialogTitle>
      <Form ref={formRef} onSubmit={handleSubmit} noValidate>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <AutocompleteGeo name="origin" label="Rota de origem" required />
            </Grid>
            <Grid item sm={6}>
              <AutocompleteGeo
                name="destination"
                label="Rota de destino"
                required
              />
            </Grid>
            <Grid item sm={12}>
              <Radio
                row
                name="is_recurrent"
                label="Esta rota é recorrente?"
                options={[
                  { label: 'Sim', value: true },
                  { label: 'Não', value: false },
                ]}
                onChange={(event, value) => {
                  if (value === 'true') {
                    setIsRecurrentRoute(true);
                  } else {
                    setIsRecurrentRoute(false);
                  }
                }}
              />
            </Grid>
            {isRecurrentRoute && (
              <>
                <Grid item sm={4}>
                  <Input
                    name="frequency"
                    type="number"
                    label="Quantas vezes"
                    required
                  />
                </Grid>

                <Grid item sm={8}>
                  <Radio
                    row
                    name="frequency_type"
                    label="Frequência"
                    options={[
                      { label: 'Diário', value: 'daily' },
                      { label: 'Semanal', value: 'weekly' },
                      { label: 'Quinzenal', value: 'biweekly' },
                      { label: 'mensal', value: 'monthly' },
                      { label: 'anual', value: 'annually' },
                    ]}
                    required
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleFinish}>
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Adicionar
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default React.forwardRef(CreateRoute);
