import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { format, parseISO } from 'date-fns';

import { AxiosError } from 'axios';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Input from '../../../../components/Input';
import InputMask from '../../../../components/InputMask';

// import { Container } from './styles';

interface IDocuments {
  motorist_id: string;
  cpf?: string;
  rg?: string;
  rg_dispatcher?: string;
  rg_uf?: string;
  rg_dispatch_date?: Date;
  pis?: string;
}

interface DocumentsProps {
  documents: IDocuments;
}

const Documents: React.FC<DocumentsProps> = ({ documents }) => {
  const formRef = React.useRef<FormHandles>(null);
  const [editMode, setEditMode] = React.useState(false);
  const { addToast } = useToast();

  const handleSubmit = React.useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        const { rg_dispatch_date } = data;
        data.rg_dispatch_date = rg_dispatch_date
          ? `${rg_dispatch_date}T00:00:00`
          : undefined;

        const schema = Yup.object().shape({
          cpf: Yup.string(),
          rg: Yup.string(),
          rg_dispatcher: Yup.string(),
          rg_uf: Yup.string(),
          rg_dispatch_date: Yup.date(),
          pis: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put(
          `/motorists/${documents.motorist_id}`,
          data,
        );
        Object.assign(documents, { ...response.data, rg_dispatch_date });

        addToast({
          type: 'success',
          title: 'Documentos alterados com sucesso!',
        });
        setEditMode(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const error = err as AxiosError;
          addToast({
            type: 'error',
            title:
              error.response?.data.message ||
              'Não foi possível atualizar os documentos. Tente novamente mais tarde',
          });
        }
      }
    },
    [addToast, documents],
  );

  const formattedRgDispatchDate = React.useMemo(() => {
    if (documents.rg_dispatch_date) {
      const birth = parseISO(String(documents.rg_dispatch_date));
      return format(birth, 'dd/MM/yyyy');
    }
    return undefined;
  }, [documents.rg_dispatch_date]);

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={documents}>
      <Table>
        <TableBody>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              CPF
            </TableCell>
            <TableCell>
              {editMode && <InputMask name="cpf" mask="999.999.999-99" />}
              {!editMode && documents.cpf}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              RG
            </TableCell>
            <TableCell>
              {editMode && <Input name="rg" />}
              {!editMode && documents.rg}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Órgão expeditor do RG
            </TableCell>
            <TableCell>
              {editMode && <Input name="rg_dispatcher" />}
              {!editMode && documents.rg_dispatcher}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              RG UF
            </TableCell>
            <TableCell>
              {editMode && <Input name="rg_uf" />}
              {!editMode && documents.rg_uf}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Data de expedição do RG
            </TableCell>
            <TableCell>
              {editMode && <Input type="date" name="rg_dispatch_date" />}
              {!editMode && formattedRgDispatchDate}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              PIS
            </TableCell>
            <TableCell>
              {editMode && <Input name="pis" />}
              {!editMode && documents.pis}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}
      >
        {!editMode && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setEditMode(true)}
          >
            Editar
          </Button>
        )}
        {editMode && (
          <div style={{ marginLeft: 16 }}>
            <Button color="secondary" onClick={() => setEditMode(false)}>
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: 16 }}
            >
              Salvar
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

export default Documents;
