import {
  Container as MuContainer,
  Typography,
  Box,
  Paper,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const Container = styled(MuContainer)({
  display: 'flex',
  flexDirection: 'column',
});

export const PageTitle = styled(Typography)({
  display: 'flex',
  marginBottom: 20,
});

export const Section = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 32,
});

export const SectionTitle = styled(Typography)({
  marginBottom: '16px',
  fontSize: '18px',
});

export const SectionContent = styled(Paper)({
  flex: 1,
  padding: '16px',
});
