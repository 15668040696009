/* eslint-disable import/no-duplicates */
import React from 'react';
import ReactDomServer from 'react-dom/server';
import { Box } from '@material-ui/core';
import { PlaceOutlined, TripOriginOutlined } from '@material-ui/icons';
import {
  MapContainer,
  Marker,
  TileLayer,
  Popup,
  Polyline,
} from 'react-leaflet';
import Leaflet, { LatLngTuple } from 'leaflet';
import MapboxDirectionsFactory from '@mapbox/mapbox-sdk/services/directions';
import { formatDistance } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import 'leaflet/dist/leaflet.css';

import { CityType } from '../../../../services/dtos/types';

const accessToken = String(process.env.REACT_APP_MAPBOX_KEY);
const directionsClient = MapboxDirectionsFactory({ accessToken });

const mapPinIcon = Leaflet.divIcon({
  html: ReactDomServer.renderToString(<PlaceOutlined htmlColor="#0e3552" />),
  iconSize: [0, 0],
  iconAnchor: [13, 24],
});

const mapDestinationIcon = Leaflet.divIcon({
  html: ReactDomServer.renderToString(
    <TripOriginOutlined htmlColor="#ff772a" />,
  ),
  iconSize: [0, 0],
  iconAnchor: [13, 12],
});

interface MapLocationProps {
  origin: CityType;
  destination: CityType;
}

interface RouteMetrics {
  duration: string;
  distance: string;
}

const MapLocation: React.FC<MapLocationProps> = ({ origin, destination }) => {
  const [coords, setCoords] = React.useState<LatLngTuple[]>([]);
  const [routeMetrics, setRouteMetrics] = React.useState<RouteMetrics>();

  React.useEffect(() => {
    const fetchRoute = async () => {
      const res = await directionsClient
        .getDirections({
          waypoints: [
            { coordinates: [Number(origin.lng), Number(origin.lat)] },
            { coordinates: [Number(destination.lng), Number(destination.lat)] },
          ],
          profile: 'driving',
          geometries: 'geojson',
        })
        .send();

      const { distance } = res.body.routes[0];
      const duration = res.body.routes[0].duration as number;

      const formattedDuration = formatDistance(0, duration * 1000, {
        includeSeconds: true,
        locale: ptBR,
      });

      setRouteMetrics({
        distance: `${Math.round(distance / 1000)} Km`,
        duration: `${formattedDuration}`,
      });

      const responseCoords = res.body.routes[0].geometry
        .coordinates as LatLngTuple[];

      const formattedCoords = responseCoords.map(coord => {
        const lat = coord[1];
        const lng = coord[0];
        return [lat, lng] as LatLngTuple;
      });

      setCoords(formattedCoords);
    };
    fetchRoute();
  }, [origin, destination]);

  return (
    <Box width="100%" height={200} display="flex">
      <MapContainer
        scrollWheelZoom={false}
        style={{ flex: 1 }}
        bounds={[
          [origin.lat, origin.lng],
          [destination.lat, destination.lng],
        ]}
      >
        <TileLayer
          attribution="&copy; SpotX Logística"
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
        />

        <Marker icon={mapPinIcon} position={[origin.lat, origin.lng]}>
          <Popup>{origin.name}</Popup>
        </Marker>
        <Marker
          icon={mapDestinationIcon}
          position={[destination.lat, destination.lng]}
        >
          <Popup>{destination.name}</Popup>
        </Marker>
        <Polyline pathOptions={{ color: '#0e3552' }} positions={coords} />
      </MapContainer>
      <Box
        px="16px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box display="flex" alignItems="center">
          <PlaceOutlined htmlColor="#0e3552" />
          <Box component="strong" ml={1.5}>
            {origin.name}
          </Box>
        </Box>
        <Box py={2} pl={3.5} ml={1.4} borderLeft="1px solid #ccc">
          <div>
            <span>Distancia real: </span>
            {routeMetrics?.distance}
          </div>
          <div>
            <span>Duração: </span>
            {routeMetrics?.duration}
          </div>
        </Box>
        <Box display="flex" alignItems="center">
          <TripOriginOutlined htmlColor="#ff772a" />
          <Box component="strong" ml={1.5}>
            {destination.name}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MapLocation;
