import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';

import { DeleteForever } from '@material-ui/icons';
import { FreightType } from '../../../../services/dtos/types';
import Input from '../../../../components/Input';
import Radio from '../../../../components/Radio';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import getValidationErrors from '../../../../utils/getValidationErrors';
import InputNumberMask from '../../../../components/InputNumberMask';
import Select from '../../../../components/Select';
import InputMask from '../../../../components/InputMask';
import getOnlyNumbers from '../../../../utils/getOnlyNumbers';
import AutocompleteGeo from '../../../../components/AutocompleteGeo';

interface FreightDataProps {
  freight: FreightType;
}

const FreightData: React.FC<FreightDataProps> = ({ freight }) => {
  const formRef = React.useRef<FormHandles>(null);
  const [editMode, setEditMode] = React.useState(false);
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = React.useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          status: Yup.string().equals([
            'pending',
            'in_origin',
            'collecting',
            'on_road',
            'in_destination',
            'delivered',
            'finished',
          ]),
          type: Yup.string().equals(['aerial', 'terrestrial', 'hotline']),
          suggested_price_type: Yup.string().equals([
            'per_ton',
            'full',
            'to_match',
            'per_kilometer',
          ]),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put(`/freights/${freight.id}`, data);
        Object.assign(freight, response.data);

        addToast({
          type: 'success',
          title: 'Frete atualizado com sucesso!',
        });
        setEditMode(false);
        history.go(0);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const error = err as AxiosError;
          addToast({
            type: 'error',
            title:
              error.response?.data.message ||
              'Não foi possível atualizar o frete. Tente novamente mais tarde',
          });
        }
      }
    },
    [addToast, freight, history],
  );

  const handleSelectedOriginZipCode = React.useCallback(async e => {
    try {
      const zipcode = String(e.target.value);
      formRef.current?.setFieldError('origin_zipcode', '');
      const { data } = await api.get(
        `https://brasilapi.com.br/api/cep/v1/${getOnlyNumbers(zipcode)}`,
      );

      formRef.current?.setData({
        origin_address: data.street,
        origin_district: data.neighborhood,
      });
    } catch (exp) {
      formRef.current?.setFieldError('origin_zipcode', 'CEP inválido');
    }
  }, []);

  const handleSelectedDestinationZipCode = React.useCallback(async e => {
    try {
      const zipcode = String(e.target.value);
      formRef.current?.setFieldError('destination_zipcode', '');
      const { data } = await api.get(
        `https://brasilapi.com.br/api/cep/v1/${getOnlyNumbers(zipcode)}`,
      );

      formRef.current?.setData({
        destination_address: data.street,
        destination_district: data.neighborhood,
      });
    } catch (exp) {
      formRef.current?.setFieldError('destination_zipcode', 'CEP inválido');
    }
  }, []);

  const handleFretebrasSync = React.useCallback(async () => {
    await api.get(`/fretebras/sync/${freight.id}`);
    addToast({
      type: 'success',
      title: 'Frete sincronizado com sucesso!',
    });
    history.go(0);
  }, [freight.id, addToast, history]);

  const handleRemoveFretebrasId = React.useCallback(async () => {
    await api.patch(`/freights/${freight.id}/remove-fretebras-id`);
    addToast({
      type: 'success',
      title: 'Frete removido com sucesso!',
    });
    history.go(0);
  }, [freight.id, addToast, history]);

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      noValidate
      initialData={freight}
    >
      <Grid container spacing={2}>
        {!freight.motorist_id && freight.status === 'pending' && (
          <Grid item sm={12} justify="flex-end" style={{ display: 'flex' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleFretebrasSync}
            >
              Sincronizar com fretebras
              <span className="material-icons">push_pin </span>
            </Button>
            {freight.fretebras_freight_id && (
              <Button
                variant="outlined"
                color="inherit"
                style={{ marginLeft: 32 }}
                onClick={handleRemoveFretebrasId}
              >
                Excluir do fretebras
                <DeleteForever />
              </Button>
            )}
          </Grid>
        )}
        <Grid item sm={4}>
          <Select
            name="type"
            options={[
              { label: 'Aéreo', value: 'aerial' },
              { label: 'Terrestre', value: 'terrestrial' },
              { label: 'Hotline', value: 'hotline' },
            ]}
            label="Tipo"
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={4}>
          <Radio
            row
            name="is_round_trip"
            label="Ida e volta?"
            options={[
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={4}>
          <Radio
            row
            name="has_toll"
            label="Inclui pedágio?"
            options={[
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={12}>
          <Input
            name="description"
            label="Descrição"
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={4}>
          <InputNumberMask
            name="suggested_price"
            label="Preço sugerido"
            numberFormat={{ prefix: 'R$ ' }}
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={4}>
          <Select
            name="suggested_price_type"
            label="Tipo de preço"
            options={[
              { label: 'Por tonelada', value: 'per_ton' },
              { label: 'Cheio', value: 'full' },
              { label: 'A combinar', value: 'to_match' },
              { label: 'Por kilômetro', value: 'per_kilometer' },
            ]}
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={4}>
          <InputNumberMask
            name="agreed_price"
            label="Preço acordado"
            numberFormat={{ prefix: 'R$ ' }}
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={3}>
          <AutocompleteGeo
            name="origin_id"
            placeholder={`Origem: ${freight?.origin?.name}`}
            registerOnlyId
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={3}>
          <InputMask
            name="origin_zipcode"
            label="CEP origem"
            mask="99.999-999"
            onBlur={handleSelectedOriginZipCode}
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={6}>
          <Input
            name="origin_address"
            label="Endereço origem"
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={2}>
          <Input
            name="origin_number"
            type="number"
            label="Número origem"
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={4}>
          <Input
            name="origin_district"
            label="Bairro origem"
            disabled={!editMode}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item sm={6}>
          <Input
            name="origin_complement"
            label="Complemento origem"
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={3}>
          <AutocompleteGeo
            name="destination_id"
            placeholder={`Destino: ${freight?.destination?.name}`}
            registerOnlyId
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={3}>
          <InputMask
            name="destination_zipcode"
            label="CEP destino"
            mask="99.999-999"
            onBlur={handleSelectedDestinationZipCode}
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={6}>
          <Input
            name="destination_address"
            label="Endereço destino"
            disabled={!editMode}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item sm={2}>
          <Input
            name="destination_number"
            label="Número destino"
            type="number"
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={4}>
          <Input
            name="destination_district"
            label="Bairro destino"
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={6}>
          <Input
            name="destination_complement"
            label="Complemento destino"
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
        </Grid>
      </Grid>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}
      >
        {!editMode && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setEditMode(true)}
          >
            Editar
          </Button>
        )}
        {editMode && (
          <div style={{ marginLeft: 16 }}>
            <Button color="secondary" onClick={() => setEditMode(false)}>
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: 16 }}
            >
              Salvar
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

export default FreightData;
