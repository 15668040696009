import { Container as MuContainer, Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const Container = styled(MuContainer)({
  display: 'flex',
  flexDirection: 'column',
});

export const PageTitle = styled(Box)({
  display: 'flex',
  marginBottom: 32,
  alignItems: 'center',
  justifyContent: 'space-between',
});
