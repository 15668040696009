import React, { useEffect, useRef } from 'react';
import { MenuItem, TextField, BaseTextFieldProps } from '@material-ui/core';
import { useField } from '@unform/core';

interface Option {
  label: string;
  value: string | number;
}

interface AppSelectProps extends BaseTextFieldProps {
  name: string;
  options: Option[];
  helperText?: string;
  label?: string;
  disabled?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
}

interface SelectRefProps {
  node: HTMLInputElement;
  value: string;
}

const Select: React.FC<AppSelectProps> = ({
  name,
  options,
  helperText,
  label,
  variant = 'outlined',
  fullWidth = true,
  ...props
}) => {
  const selectRef = useRef<SelectRefProps>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue(ref: SelectRefProps) {
        if (ref.node.value && ref.node.value.length > 0) {
          return ref.node.value;
        }
        return undefined;
      },
      setValue(ref: SelectRefProps, value: string | undefined) {
        if (ref.node.value && value) {
          ref.node.value = value;
        }
      },
    });
  }, [registerField, fieldName]);

  return (
    <TextField
      style={{ backgroundColor: '#fff' }}
      inputRef={selectRef}
      select
      label={label}
      defaultValue={defaultValue || ''}
      error={!!error}
      helperText={error || helperText}
      variant={variant}
      fullWidth={fullWidth}
      {...props}
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default React.memo(Select);
