import React from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../services/api';
import { VehicleType } from '../../../services/dtos/types';

import VehicleData from './VehicleData';

import {
  Container,
  PageTitle,
  Section,
  SectionTitle,
  SectionContent,
} from './styles';

interface RouteParams {
  id: string;
}

const ShowVehicle: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const [vehicle, setVehicle] = React.useState({} as VehicleType);

  React.useEffect(() => {
    document.title = 'SpotX - Informações do veículo';
  }, []);

  React.useEffect(() => {
    const loadVehicle = async () => {
      const response = await api.get(`/vehicles/${id}`);
      setVehicle(response.data);
    };

    loadVehicle();
  }, [id]);

  return (
    <Container>
      <PageTitle variant="h5">Informações do veículo</PageTitle>

      <Section>
        <SectionTitle color="textSecondary">Veículo</SectionTitle>
        <SectionContent>
          <VehicleData vehicle={vehicle} />
        </SectionContent>
      </Section>
    </Container>
  );
};

export default ShowVehicle;
