import { ListItem, Collapse as MuiCollapse } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';

export const Link = styled(NavLink)({
  textDecoration: 'none',
  color: '#333',
  '&.active div': {
    color: '#fff',
    backgroundColor: '#3f51b5',
  },
});

export const SubItem = styled(ListItem)({
  paddingLeft: 32,
});

export const Collapse = styled(MuiCollapse)({
  backgroundColor: 'rgb(242, 245, 249)',
  boxShadow: 'inset 1px 1px 4px rgba(0, 0, 0, 0.25)',
});
