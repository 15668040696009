import { CssBaseline } from '@material-ui/core';
import React, { createContext, useCallback, useContext, useState } from 'react';
import NavBar from './NavBar';
import SideMenu from './SideMenu';
import { useStyles } from './styles';

interface ContextData {
  openSideMenu: boolean;
  toggleOpenSideMenu(): void;
}

const LayoutContext = createContext<ContextData>({} as ContextData);

const Layout: React.FC = ({ children }) => {
  const [openSideMenu, setOpenSideMenu] = useState(true);
  const classes = useStyles();

  const toggleOpenSideMenu = useCallback(() => {
    setOpenSideMenu(state => !state);
  }, []);

  return (
    <LayoutContext.Provider value={{ openSideMenu, toggleOpenSideMenu }}>
      <CssBaseline />
      <div className={classes.root}>
        <NavBar />
        <SideMenu />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <div className={classes.container}>{children}</div>
        </main>
      </div>
    </LayoutContext.Provider>
  );
};

function useLayout(): ContextData {
  const context = useContext(LayoutContext);
  if (!context)
    throw new Error('useLayout must be used within an AuthProvider');
  return context;
}

export { Layout, useLayout };
