/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { TextField, TextFieldProps } from '@material-ui/core';
import { useField } from '@unform/core';
import parseNumber from '../utils/parseNumber';

type AppInputProps = TextFieldProps & {
  name: string;
  numberFormat?: NumberFormatProps;
};

type NumberFormatCustomProps = {
  inputRef: (instance: NumberFormat | null) => void;
};

function NumberFormatCustom({ inputRef, ...other }: NumberFormatCustomProps) {
  return <NumberFormat {...other} getInputRef={inputRef} decimalScale={2} />;
}

const InputNumberMask: React.FC<AppInputProps> = ({
  name,
  helperText,
  fullWidth = true,
  variant = 'outlined',
  numberFormat,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue(ref: HTMLInputElement) {
        if (ref.value && ref.value.length > 0) {
          return parseNumber(ref.value);
        }
        return undefined;
      },
      setValue(ref: HTMLInputElement, value: any) {
        if (value && value.length > 0) {
          ref.value = value;
        }
      },
    });
  }, [registerField, fieldName]);

  return (
    <TextField
      style={{ backgroundColor: '#fff' }}
      inputRef={inputRef}
      name={name}
      defaultValue={defaultValue}
      error={!!error}
      helperText={error || helperText}
      fullWidth={fullWidth}
      variant={variant}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        inputProps: {
          ...numberFormat,
          isNumericString: true,
          thousandSeparator: '.',
          decimalSeparator: ',',
        },
      }}
      {...props}
    />
  );
};

export default React.memo(InputNumberMask);
