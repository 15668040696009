import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

export interface AlertDialogHandles {
  toggleDialog: (toggle: boolean) => void;
}

interface AlertDialogProps {
  alertTitle: string;
  alertDescription: string;
  alertId?: string;
  children: React.ReactNode;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps,
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AlertDialog: React.RefForwardingComponent<
  AlertDialogHandles,
  AlertDialogProps
> = ({ alertTitle, alertDescription, children, alertId = 'slide' }, ref) => {
  const [open, setOpen] = React.useState(false);

  const toggleDialog = React.useCallback((toggle: boolean) => {
    setOpen(toggle);
  }, []);

  React.useImperativeHandle(ref, () => {
    return {
      toggleDialog,
    };
  });

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => toggleDialog(false)}
      aria-labelledby={`alert-dialog-${alertId}-title`}
      aria-describedby={`alert-dialog-${alertId}-description`}
    >
      <DialogTitle id={`alert-dialog-${alertId}-title`}>
        {alertTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={`alert-dialog-${alertId}-description`}>
          {alertDescription}
        </DialogContentText>
      </DialogContent>
      <DialogActions>{children}</DialogActions>
    </Dialog>
  );
};

export default React.forwardRef(AlertDialog);
