import React from 'react';
import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { parseISO, format } from 'date-fns';

import { ExpenseType } from '../../../../services/dtos/types';
import Input from '../../../../components/Input';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Radio from '../../../../components/Radio';
import InputNumberMask from '../../../../components/InputNumberMask';
import parseCurrency from '../../../../utils/parseCurrency';

interface ExpenseDataProps {
  expense?: ExpenseType;
}

const ExpenseData: React.FC<ExpenseDataProps> = ({ expense }) => {
  const formRef = React.useRef<FormHandles>(null);
  const [editMode, setEditMode] = React.useState(false);
  const { addToast } = useToast();

  const handleSubmit = React.useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          is_urgent: Yup.boolean(),
          is_paid: Yup.boolean(),
          name: Yup.string(),
          description: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put(`/expenses/${expense?.id}`, data);
        Object.assign(expense, response.data);

        addToast({
          type: 'success',
          title: 'Despesa atualizada com sucesso!',
        });
        setEditMode(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const error = err as AxiosError;
          addToast({
            type: 'error',
            title:
              error.response?.data.message ||
              'Não foi possível atualizar a despesa. Tente novamente mais tarde',
          });
        }
      }
    },
    [addToast, expense],
  );

  const formattedCreatedAt = React.useMemo(() => {
    if (expense?.created_at) {
      const date = parseISO(String(expense.created_at));
      return format(date, "dd/MM/yyyy 'às' HH:mm");
    }
    return '';
  }, [expense?.created_at]);

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      noValidate
      initialData={expense}
    >
      <Table>
        <TableBody>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Urgente?
            </TableCell>
            <TableCell>
              {editMode && (
                <Radio
                  row
                  name="is_urgent"
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                />
              )}
              {!editMode && (expense?.is_urgent ? 'Sim' : 'Não')}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Pago?
            </TableCell>
            <TableCell>
              {editMode && (
                <Radio
                  row
                  name="is_paid"
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                />
              )}
              {!editMode && (expense?.is_paid ? 'Sim' : 'Não')}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Despesa
            </TableCell>
            <TableCell>
              {editMode ? <Input name="name" /> : expense?.name}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Valor
            </TableCell>
            <TableCell>
              {editMode ? (
                <InputNumberMask
                  name="value"
                  numberFormat={{ prefix: 'R$ ' }}
                />
              ) : (
                parseCurrency(expense?.value)
              )}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Descrição
            </TableCell>
            <TableCell>
              {editMode ? <Input name="description" /> : expense?.description}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Client
            </TableCell>
            <TableCell>{expense?.operation?.requester?.name}</TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Cadastrado por
            </TableCell>
            <TableCell>{expense?.user?.name}</TableCell>
          </TableRow>

          {expense?.status_updater && (
            <TableRow hover>
              <TableCell component="th" variant="head" scope="row">
                Atualizado por
              </TableCell>
              <TableCell>{expense?.status_updater.name}</TableCell>
            </TableRow>
          )}
          {expense?.expense_file_url && (
            <TableRow hover>
              <TableCell component="th" variant="head" scope="row">
                Arquivo (comprovante)
              </TableCell>
              <TableCell>
                <Link
                  href={expense?.expense_file_url}
                  target="_blank"
                  rel="norefer"
                >
                  {expense?.expense_file_url}
                </Link>
              </TableCell>
            </TableRow>
          )}
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Cadastrado em
            </TableCell>
            <TableCell>{formattedCreatedAt}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}
      >
        {!editMode && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setEditMode(true)}
          >
            Editar
          </Button>
        )}
        {editMode && (
          <div style={{ marginLeft: 16 }}>
            <Button color="secondary" onClick={() => setEditMode(false)}>
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: 16 }}
            >
              Salvar
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

export default ExpenseData;
