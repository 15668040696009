import { Typography } from '@material-ui/core';
import React from 'react';

const Dashboard: React.FC = () => {
  React.useEffect(() => {
    document.title = 'SpotX - Início';
  }, []);

  return (
    <Typography component="h1" variant="h5">
      Dashboard
    </Typography>
  );
};

export default Dashboard;
