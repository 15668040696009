import { Box, Container as MuContainer, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const Container = styled(MuContainer)({
  display: 'flex',
  flexDirection: 'column',
});

export const PageTitle = styled(Typography)({
  display: 'flex',
  marginBottom: 20,
});

export const Section = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 32,
  paddingBottom: 32,
  borderBottom: '1px solid #ddd',
});

export const SectionDescription = styled(Typography)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 16,
  fontSize: 18,
  color: '#666',
});

export const SectionContent = styled(Box)({
  flex: 1,
});
