import React from 'react';
import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { AxiosError } from 'axios';
import CreateAttachment, { DialogHandles } from './CreateAttachment';
import { MotoristAttachmentType } from '../../../../services/dtos/types';
import AlertDialog, {
  AlertDialogHandles,
} from '../../../../components/AlertDialog';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';

interface AttachmentsProps {
  motorist_id: string;
  attachments?: MotoristAttachmentType[];
}

const Attachments: React.FC<AttachmentsProps> = ({
  motorist_id,
  attachments,
}) => {
  const alertDialogRef = React.useRef<AlertDialogHandles>(null);
  const attachmentIdRef = React.useRef<string | null>(null);
  const createAttachmentRef = React.useRef<DialogHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();

  const handleClickOpenDialogCreateAttachment = React.useCallback(() => {
    createAttachmentRef.current?.openDialog();
  }, []);

  const getAttachmentType = React.useCallback((type: string) => {
    switch (type) {
      case 'cpf':
        return 'CPF';
      case 'rg':
        return 'RG';
      case 'cnh':
        return 'CNH';
      case 'address_proof':
        return 'Comprovante de residência';
      case 'dependent_doc':
        return 'Documento de dependente';
      case 'other':
        return 'Outro';
      default:
        return '';
    }
  }, []);

  const handleDeleteAttachment = React.useCallback((attachment_id: string) => {
    attachmentIdRef.current = attachment_id;
    alertDialogRef.current?.toggleDialog(true);
  }, []);

  const cancelDelete = React.useCallback(() => {
    alertDialogRef.current?.toggleDialog(false);
  }, []);

  const confirmDelete = React.useCallback(async () => {
    alertDialogRef.current?.toggleDialog(false);
    try {
      await api.delete(`/motorists/attachments/${attachmentIdRef.current}`);
      addToast({
        type: 'success',
        title: 'Anexo removido com sucesso!',
      });
      history.go(0);
    } catch (err) {
      const error = err as AxiosError;
      addToast({
        type: 'error',
        title:
          error.response?.data.message || 'Não foi possivel deletar o arquivo',
      });
    }
  }, [addToast, history]);

  return (
    <>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Tipo</TableCell>
            <TableCell>Descrição</TableCell>
            <TableCell colSpan={2}>Arquivo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attachments &&
            attachments.map(attach => (
              <TableRow key={attach.id} hover>
                <TableCell>{getAttachmentType(attach.type)}</TableCell>
                <TableCell>{attach.description}</TableCell>
                <TableCell>
                  <Link
                    href={attach.attachment_file_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {attach.name || getAttachmentType(attach.type)}
                  </Link>
                </TableCell>
                <TableCell align="right">
                  <Button
                    color="secondary"
                    onClick={() => handleDeleteAttachment(attach.id)}
                  >
                    <DeleteOutlineIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClickOpenDialogCreateAttachment}
        >
          Adicionar
        </Button>
      </div>
      <CreateAttachment ref={createAttachmentRef} motorist_id={motorist_id} />
      <AlertDialog
        ref={alertDialogRef}
        alertTitle="Tem certeza que deseja excluir?"
        alertDescription="Tem certeza que deseja excluir o anexo?"
      >
        <Button onClick={cancelDelete} color="secondary">
          Cancelar
        </Button>
        <Button onClick={confirmDelete} color="primary">
          Excluir
        </Button>
      </AlertDialog>
    </>
  );
};

export default Attachments;
