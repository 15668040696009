import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Button, Grid } from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import Input from '../../../../../components/Input';
import api from '../../../../../services/api';
import { useToast } from '../../../../../hooks/toast';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import InputMask from '../../../../../components/InputMask';
import { MotoristType } from '../../../../../services/dtos/types';

export interface LinkMotoristToFreightHandles {
  toggleDialog: (toggle: boolean) => void;
  setMotorist: (motorist: MotoristType) => void;
}

interface LinkMotoristToFreightProps {
  freight_id: string;
  is_fretebras: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps,
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LinkMotoristToFreight: React.RefForwardingComponent<
  LinkMotoristToFreightHandles,
  LinkMotoristToFreightProps
> = ({ freight_id, is_fretebras }, ref) => {
  const formRef = React.useRef<FormHandles>(null);
  const motoristRef = React.useRef({
    data: {} as MotoristType,
  });
  const [open, setOpen] = React.useState(false);
  const { addToast } = useToast();
  const history = useHistory();

  const toggleDialog = React.useCallback((toggle: boolean) => {
    setOpen(toggle);
    if (!toggle) {
      formRef.current?.setFieldValue('operator_phone', ' ');
      formRef.current?.setFieldValue('client_phone', ' ');
      formRef.current?.setFieldValue('client_email', ' ');
      formRef.current?.setErrors({});
    }
  }, []);

  const setMotorist = React.useCallback((motorist: MotoristType) => {
    motoristRef.current.data = motorist;
  }, []);

  React.useImperativeHandle(ref, () => {
    return {
      toggleDialog,
      setMotorist,
    };
  });

  const handleSubmit = React.useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          operator_phone: Yup.string().required('Campo obrigatório'),
          client_phone: Yup.string().required('Campo obrigatório'),
          client_email: Yup.string().email('Email inválido'),
        });

        await schema.validate(data, { abortEarly: false });

        const motorist = motoristRef.current.data;

        await api.post('/freights/motorist/link', {
          freight_id,
          motorist_id: motorist.id,
          vehicle_id: motorist.vehicle?.id,
        });
        if (is_fretebras) {
          await api.patch(`/freights/${freight_id}/remove-fretebras-id`);
        }

        // endpoint para criar notificação no BD
        await api.post('/notifications/freight', {
          freight_id,
          client_phone: data.client_phone,
          operator_phone: data.operator_phone,
          client_email: data.client_email,
        });

        // endpoint para disparar notificação
        api.post('/notifications/whatsapp/link', {
          freight_id,
        });

        addToast({
          type: 'success',
          title: 'Motorista vinculado com sucesso!',
        });

        setOpen(false);
        history.go(0);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const error = err as AxiosError;
          addToast({
            type: 'error',
            title:
              error.response?.data.message ||
              'Não foi possível vincular o motorista. Tente novamente mais tarde',
          });
        }
      }
    },
    [addToast, freight_id, is_fretebras, history],
  );

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => toggleDialog(false)}
      aria-labelledby="alert-dialog-link-motorist-title"
      aria-describedby="alert-dialog-link-motorist-content"
    >
      <Form ref={formRef} onSubmit={handleSubmit} noValidate>
        <DialogTitle id="alert-dialog-link-motorist-title">
          Vincular motorista ao frete
        </DialogTitle>
        <DialogContent>
          <p>
            O motorista será vinculado ao frete. Para isso você precisa
            preencher os dados abaixo para ativar as notificações
          </p>
          <DialogContentText id="alert-dialog-link-motorist-content">
            <Grid container spacing={1}>
              <Grid item sm={6}>
                <InputMask
                  name="operator_phone"
                  label="Telefone do operador"
                  mask="(99) 9 9999-9999"
                  required
                />
              </Grid>

              <Grid item sm={6}>
                <InputMask
                  name="client_phone"
                  label="Telefone do cliente"
                  mask="(99) 9 9999-9999"
                  required
                />
              </Grid>

              <Grid item sm={12}>
                <Input
                  type="email"
                  name="client_email"
                  label="Email do cliente"
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => toggleDialog(false)}>
            Cancelar
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Vincular
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default React.forwardRef(LinkMotoristToFreight);
