import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  Button,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import api from '../../../services/api';
import { ClientType, PaginateListType } from '../../../services/dtos/types';
import { Container, PageTitle } from './styles';

const ListClients: React.FC = () => {
  const [clients, setClients] = useState<PaginateListType<ClientType>>({
    current_page: 0,
    from: 1,
    to: 15,
    per_page: 15,
    total: 15,
    data: [],
  } as PaginateListType<ClientType>);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(15);

  React.useEffect(() => {
    document.title = 'SpotX - Todos os clientes';
  }, []);

  useEffect(() => {
    api
      .get('clients', { params: { page: page + 1, per_page: perPage } })
      .then(response => {
        setClients(response.data);
      });
  }, [page, perPage]);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangePerPage = useCallback(event => {
    setPerPage(event.target.value);
    setPage(0);
  }, []);

  const dataRows = useMemo(() => {
    return clients.data;
  }, [clients]);

  return (
    <Container>
      <PageTitle>
        <Typography component="h1" variant="h5">
          Clientes
        </Typography>
        <Button
          component={Link}
          to="/clients/create"
          variant="contained"
          color="primary"
        >
          Novo cliente
        </Button>
      </PageTitle>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Empresa</TableCell>
              <TableCell align="right">CNPJ</TableCell>
              <TableCell align="right">Telefone</TableCell>
              <TableCell align="right">Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows.map(row => (
              <TableRow
                key={row.id}
                component={Link}
                to={`/clients/show/${row.id}`}
                hover
                style={{ textDecoration: 'none' }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.cnpj}</TableCell>
                <TableCell align="right">{row.phone}</TableCell>
                <TableCell align="right">{row.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              {clients.data.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[10, 15, 25, 50, 100]}
                  count={clients.total}
                  rowsPerPage={perPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangePerPage}
                  labelRowsPerPage="linhas por página"
                />
              )}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ListClients;
