import { Button, Grid } from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Autocomplete from '../../../components/Autocomplete';
import Input from '../../../components/Input';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';

import { Container, PageTitle } from './styles';

interface OptionType {
  label: string;
  value: string;
}

interface CategoryAndBodyType {
  id: string;
  name: string;
  type: string;
}

interface MotoristType {
  id: string;
  name: string;
  cpf: string;
}

const CreateVehicle: React.FC = () => {
  const formRef = React.useRef<FormHandles>(null);
  const [categories, setCategories] = React.useState<OptionType[]>([]);
  const [bodies, setBodies] = React.useState<OptionType[]>([]);
  const [motorists, setMotorists] = React.useState<OptionType[]>([]);
  const [owners, setOwners] = React.useState<OptionType[]>([]);
  const history = useHistory();
  const { addToast } = useToast();

  React.useEffect(() => {
    document.title = 'SpotX - Cadastrar veículo';
  }, []);

  React.useEffect(() => {
    const loadCategories = async () => {
      const response = await api.get<CategoryAndBodyType[]>(
        '/vehicles/categories/search',
      );
      const formattedData = response.data.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setCategories(formattedData);
    };

    const loadBodies = async () => {
      const response = await api.get<CategoryAndBodyType[]>(
        '/vehicles/bodies/search',
      );
      const formattedData = response.data.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setBodies(formattedData);
    };

    loadCategories();
    loadBodies();
  }, []);

  const currentYear = React.useMemo(() => {
    return new Date().getFullYear();
  }, []);

  const handleSubmit = React.useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          motorist_id: Yup.string().uuid().required('Campo obrigatório'),
          owner_id: Yup.string(),
          vehicle_category_id: Yup.string(),
          vehicle_body_id: Yup.string(),
          license_plate: Yup.string().length(
            7,
            'A placa deve conter 7 dígitos',
          ),
          license_uf: Yup.string().length(2, '2 caracteres'),
          brand: Yup.string(),
          model: Yup.string(),
          release_year: Yup.number()
            .notRequired()
            .min(1900, 'mínimo 1900')
            .max(currentYear, `máximo ${currentYear}`),
          renavam: Yup.string(),
          chassi: Yup.string(),
          antt: Yup.string(),
          capacity_m3: Yup.string(),
          capacity_tara: Yup.string(),
          capacity_kg: Yup.string(),
          has_tracker: Yup.string(),
          has_insurance: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('/vehicles', data);

        history.push('/vehicles');
        addToast({
          title: 'Veículo adicionado com sucesso!',
          type: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Não foi possível cadastrar o veículo',
          });
        }
      }
    },
    [history, addToast, currentYear],
  );

  const handleSearchMotorists = React.useCallback(
    (e, value: string, reason) => {
      if (value.length < 3) return;
      if (reason === 'input') {
        const search = async () => {
          const response = await api.get<MotoristType[]>(
            `/motorists/search?name=${value}`,
          );
          const formattedData = response.data.map(item => {
            return {
              label: `${item.name} - ${item.cpf}`,
              value: item.id,
            };
          });
          setMotorists(formattedData);
        };

        setTimeout(() => {
          search();
        }, 500);
      }
    },
    [],
  );

  const handleSearchOwners = React.useCallback(
    async (e, value: string, reason) => {
      if (reason === 'reset') return;
      if (value.length < 3) return;
      setTimeout(async () => {
        const response = await api.get<MotoristType[]>(
          `/motorists/search?name=${value}`,
        );
        const formattedData = response.data.map(item => {
          return {
            label: `${item.name} - ${item.cpf}`,
            value: item.id,
          };
        });
        setOwners(formattedData);
      }, 500);
    },
    [],
  );

  return (
    <Container>
      <PageTitle variant="h5">Cadastrar veículo</PageTitle>

      <Form ref={formRef} onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item sm={6} md={6}>
            <Autocomplete
              name="motorist_id"
              variant="outlined"
              label="Motorista"
              required
              options={motorists}
              onInputChange={handleSearchMotorists}
            />
          </Grid>
          <Grid item sm={6} md={6}>
            <Autocomplete
              name="owner_id"
              variant="outlined"
              label="Dono"
              options={owners}
              onInputChange={handleSearchOwners}
            />
          </Grid>
          <Grid item sm={4} md={4}>
            <Autocomplete
              name="vehicle_category_id"
              variant="outlined"
              label="Categoria"
              options={categories}
            />
          </Grid>
          <Grid item sm={4} md={4}>
            <Autocomplete
              name="vehicle_body_id"
              variant="outlined"
              label="Carroceria"
              options={bodies}
            />
          </Grid>
          <Grid item sm={4} md={4}>
            <Input name="license_plate" variant="outlined" label="Placa" />
          </Grid>
          <Grid item sm={4} md={3}>
            <Input
              name="license_uf"
              variant="outlined"
              label="UF licenciamento"
            />
          </Grid>
          <Grid item sm={4} md={4}>
            <Input name="brand" variant="outlined" label="Marca" />
          </Grid>
          <Grid item sm={4} md={3}>
            <Input name="model" variant="outlined" label="Modelo" />
          </Grid>
          <Grid item sm={4} md={2}>
            <Input name="release_year" variant="outlined" label="Ano" />
          </Grid>
          <Grid item sm={4} md={6}>
            <Input name="renavam" variant="outlined" label="Renavam" />
          </Grid>
          <Grid item sm={4} md={6}>
            <Input name="chassi" variant="outlined" label="Chassi" />
          </Grid>
          <Grid item sm={4} md={6}>
            <Input name="antt" variant="outlined" label="ANTT" />
          </Grid>
          <Grid item sm={4} md={3}>
            <Input
              name="capacity_m3"
              variant="outlined"
              label="Capacidade m3"
            />
          </Grid>
          <Grid item sm={4} md={3}>
            <Input
              name="capacity_tara"
              variant="outlined"
              label="Capacidade tara"
            />
          </Grid>
          <Grid item sm={4} md={3}>
            <Input
              name="capacity_kg"
              variant="outlined"
              label="Capacidade Kg"
            />
          </Grid>
          <Grid item sm={4} md={6}>
            <Input
              name="has_tracker"
              variant="outlined"
              label="Tem rastreador"
            />
          </Grid>
          <Grid item sm={4} md={3}>
            <Input name="has_insurance" variant="outlined" label="Tem seguro" />
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 20,
            marginTop: 20,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            Cadastrar
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default CreateVehicle;
