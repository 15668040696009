import { Container as MuContainer, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const Container = styled(MuContainer)({
  display: 'flex',
  flexDirection: 'column',
});

export const PageTitle = styled(Typography)({
  display: 'flex',
  marginBottom: 20,
});
