/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import throttle from 'lodash/throttle';
import { useField } from '@unform/core';
import { CityType } from '../services/dtos/types';
import api from '../services/api';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  input: {
    backgroundColor: '#fff',
  },
}));

interface AppAutocompleteCitiesProps extends BaseTextFieldProps {
  name: string;
  registerOnlyId?: boolean;
}

const AutocompleteGeo: React.FC<AppAutocompleteCitiesProps> = ({
  name,
  helperText,
  variant = 'outlined',
  registerOnlyId = false,
  disabled,
  ...props
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState<CityType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<CityType[]>([]);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  React.useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      // path: 'value',
      getValue(ref: HTMLInputElement) {
        const input = ref.value;
        if (input && input.length > 0) {
          if (value) {
            if (registerOnlyId) {
              return value.ibge_id;
            }
            return value;
          }
          return input;
        }
        return undefined;
      },
    });
  }, [registerField, fieldName, value, registerOnlyId]);

  const fetch = React.useMemo(
    () =>
      throttle(
        async (
          request: { input: string },
          callback: (results?: CityType[]) => void,
        ) => {
          const response = await api.get(
            `/cities/search?name=${request.input}`,
          );
          callback(response.data);
        },
        1000,
      ),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      return undefined;
    }

    fetch({ input: inputValue }, (results?: CityType[]) => {
      if (active) {
        let newOptions = [] as CityType[];

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <Autocomplete
      noOptionsText="Digite o nome da cidade"
      disabled={disabled}
      getOptionLabel={option => {
        return typeof option === 'string'
          ? option
          : `${option.name}, ${option.state.name}`;
      }}
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      defaultValue={defaultValue}
      onChange={(event: any, newValue: CityType | null, reason) => {
        if (reason === 'select-option') {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
        }
        if (reason === 'clear') {
          setValue(null);
        }
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'input') setInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextField
          className={classes.input}
          name={name}
          inputRef={inputRef}
          error={!!error}
          helperText={error || helperText}
          variant={variant}
          {...props}
          {...params}
        />
      )}
      renderOption={option => {
        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs style={{ fontWeight: 700 }}>
              {option.name}
              <Typography variant="body2" color="textSecondary">
                {option.state.name}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default React.memo(AutocompleteGeo);
