import React, { ChangeEvent } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Form } from '@unform/web';
import { Alert } from '@material-ui/lab';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import Select from '../../../../../components/Select';
import Input from '../../../../../components/Input';
import { useToast } from '../../../../../hooks/toast';
import api from '../../../../../services/api';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import { FreightAttachmentType } from '../../../../../services/dtos/types';

export interface DialogHandles {
  openDialog: () => void;
}

interface CreateAttachmentProps {
  freight_id: string;
}

const steps = ['Preencha os dados', 'Upload do documento'];

const CreateAttachment: React.ForwardRefRenderFunction<
  DialogHandles,
  CreateAttachmentProps
> = ({ freight_id }, ref) => {
  const formRef = React.useRef<FormHandles>(null);

  const [visible, setVisible] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [attachment, setAttachment] = React.useState<
    FreightAttachmentType | undefined
  >();
  const { addToast } = useToast();
  const history = useHistory();

  const handleOpenDialog = React.useCallback(() => {
    setVisible(true);
  }, []);

  React.useImperativeHandle(ref, () => {
    return {
      openDialog: handleOpenDialog,
    };
  });

  const handleSubmit = React.useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          type: Yup.string()
            .equals(
              ['nf-e', 'ct-e', 'ciot', 'mdf-e', 'other'],
              'Opção inválida',
            )
            .required('Campo obrigatório'),
          name: Yup.string(),
          description: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.post(`/freights/attachments`, {
          ...data,
          freight_id,
        });

        setAttachment(response.data);
        setActiveStep(state => state + 1);

        addToast({
          type: 'success',
          title: 'Sucesso! Agora faça o upload do documento',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const error = err as AxiosError;
          addToast({
            type: 'error',
            title:
              error.response?.data.message ||
              'Não foi possível adicionar o anexo. Tente novamente mais tarde',
          });
        }
      }
    },
    [addToast, freight_id],
  );

  const handleUploadImage = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      try {
        if (e.target.files && attachment) {
          const data = new FormData();
          data.append('attachment', e.target.files[0]);

          api.patch(`/freights/attachments/${attachment.id}`, data).then(() => {
            setActiveStep(state => state + 1);

            addToast({
              type: 'success',
              title: 'Upload realizado com sucesso!',
            });
          });
        }
      } catch {
        addToast({
          type: 'error',
          title: 'Não foi possível fazer upload da imagem!',
        });
      }
    },
    [addToast, attachment],
  );

  const handleFinish = React.useCallback(() => {
    setVisible(false);
    setActiveStep(0);
    history.go(0);
  }, [history]);

  return (
    <Dialog open={visible} maxWidth="xs" fullWidth>
      <DialogTitle>Adicionar um anexo</DialogTitle>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === 0 && (
        <Form ref={formRef} onSubmit={handleSubmit}>
          <DialogContent>
            <Select
              margin="dense"
              name="type"
              label="Tipo de documento"
              options={[
                { label: 'NF-e', value: 'nf-e' },
                { label: 'CT-e', value: 'ct-e' },
                { label: 'CIOT', value: 'ciot' },
                {
                  label: 'MDF-e',
                  value: 'mdf-e',
                },
                { label: 'Outro', value: 'other' },
              ]}
            />
            <Input name="name" label="Nome do arquivo" margin="dense" />
            <Input name="description" label="Descrição" margin="dense" />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleFinish}>
              Cancelar
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Próximo
            </Button>
          </DialogActions>
        </Form>
      )}
      {activeStep === 1 && (
        <DialogContent>
          <input name="attach" type="file" onChange={handleUploadImage} />
        </DialogContent>
      )}
      {activeStep === 2 && (
        <>
          <DialogContent>
            <Alert severity="success" style={{ fontSize: 18 }}>
              Upload realizado com sucesso!
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleFinish}>
              Finalizar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default React.forwardRef(CreateAttachment);
