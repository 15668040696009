import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import {
  CheckinType,
  MotoristType,
  VehicleType,
} from '../../../../services/dtos/types';
import api from '../../../../services/api';
import AlertDialog, {
  AlertDialogHandles,
} from '../../../../components/AlertDialog';
import { useToast } from '../../../../hooks/toast';
import UpdateCheckin, { UpdateCheckinHandles } from './UpdateCheckin';

interface SelectedMotoristProps {
  freight_id: string;
  freight_status?: string;
  motorist?: MotoristType;
  vehicle?: VehicleType;
}

const SelectedMotorist: React.FC<SelectedMotoristProps> = ({
  freight_id,
  freight_status,
  motorist,
  vehicle,
}) => {
  const alertDialogRef = React.useRef<AlertDialogHandles>(null);
  const updateCheckinRef = React.useRef<UpdateCheckinHandles>(null);
  const [status, setStatus] = React.useState(freight_status);
  const [lastCheckin, setLastCheckin] = React.useState<CheckinType>({
    city: {
      name: '',
      state: { uf: '' },
    },
  } as CheckinType);
  const history = useHistory();
  const { addToast } = useToast();

  React.useEffect(() => {
    if (motorist && status === 'on_road') {
      api.get(`/motorists/checkins/last/${motorist.id}`).then(response => {
        setLastCheckin(response.data);
      });
    }
  }, [motorist, status]);

  const handleUnlinkMotorist = React.useCallback(async () => {
    alertDialogRef.current?.toggleDialog(true);
  }, []);

  const handleCancelUnlink = React.useCallback(() => {
    alertDialogRef.current?.toggleDialog(false);
  }, []);

  const handleConfirmUnlink = React.useCallback(async () => {
    await api.post('/freights/motorist/unlink', { freight_id });
    history.go(0);
  }, [freight_id, history]);

  const getStatus = React.useMemo(() => {
    const result = {
      label: '',
      next: '',
      labelNext: '',
    };

    if (status === 'in_origin') {
      result.label = 'Indo para coleta';
      result.next = 'collecting';
      result.labelNext = 'Pronto para coleta';
    }
    if (status === 'collecting') {
      result.label = 'Pronto para coleta';
      result.next = 'ready';
      result.labelNext = 'Carregado';
    }
    if (status === 'ready') {
      result.label = 'Carregado';
      result.next = 'on_road';
      result.labelNext = 'Em viagem';
    }
    if (status === 'on_road') {
      result.label = 'Em viagem';
      result.next = 'in_destination';
      result.labelNext = 'No destino';
    }
    if (status === 'in_destination') {
      result.label = 'No destino';
      result.next = 'delivered';
      result.labelNext = 'Descarregado';
    }
    if (status === 'delivered') {
      result.label = 'Descarregado';
    }

    return result;
  }, [status]);

  const handleChangeStatusFreight = React.useCallback(
    async (newStatus: string) => {
      await api.put(`/freights/${freight_id}/status`, { status: newStatus });
      api.get(`/notifications/whatsapp/freight-status/${freight_id}`);
      addToast({
        type: 'success',
        title: 'Status alterado com sucesso!',
      });
      setStatus(newStatus);
      if (newStatus === 'delivered') history.go(0);
    },
    [freight_id, addToast, history],
  );

  return (
    <>
      <Table>
        <TableBody>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Nome
            </TableCell>
            <TableCell>{motorist?.name}</TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Status
            </TableCell>
            <TableCell>{getStatus.label}</TableCell>
          </TableRow>
          {status === 'on_road' && (
            <TableRow hover>
              <TableCell component="th" variant="head" scope="row">
                Checkin
              </TableCell>
              <TableCell>
                {lastCheckin.city.name}
                <span>/</span>
                {lastCheckin.city.state.uf}
                <Button
                  variant="outlined"
                  style={{ marginLeft: 16 }}
                  onClick={() => updateCheckinRef.current?.toggleDialog(true)}
                >
                  Atualizar
                </Button>
              </TableCell>
              {motorist && (
                <UpdateCheckin
                  ref={updateCheckinRef}
                  motorist_id={motorist.id}
                  freight_id={freight_id}
                />
              )}
            </TableRow>
          )}

          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Telefone
            </TableCell>
            <TableCell>{motorist?.phone}</TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Veículo
            </TableCell>
            <TableCell>{vehicle?.vehicle_category?.name}</TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Carroceria
            </TableCell>
            <TableCell>{vehicle?.vehicle_body?.name}</TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Ano
            </TableCell>
            <TableCell>{vehicle?.release_year}</TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              #
            </TableCell>
            <TableCell>
              <Link to={`/motorists/show/${motorist?.id}`} color="primary">
                Ver todas as informações
              </Link>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {status === 'in_origin' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 16,
          }}
        >
          <Button color="secondary" onClick={handleUnlinkMotorist}>
            Desvincular
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleChangeStatusFreight(getStatus.next)}
          >
            {getStatus.labelNext}
          </Button>
        </div>
      )}
      {['collecting', 'ready', 'on_road', 'in_destination'].includes(
        String(status),
      ) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: 16,
          }}
        >
          <span style={{ marginRight: 12 }}>Informar status para: </span>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleChangeStatusFreight(getStatus.next)}
          >
            {getStatus.labelNext}
          </Button>
        </div>
      )}
      <AlertDialog
        ref={alertDialogRef}
        alertTitle="Tem certeza que quer desvincular este motorista?"
        alertDescription="O motorista será desvinculado ao frete"
      >
        <Button color="secondary" onClick={handleCancelUnlink}>
          Cancelar
        </Button>
        <Button color="primary" onClick={handleConfirmUnlink}>
          Desvincular
        </Button>
      </AlertDialog>
    </>
  );
};

export default SelectedMotorist;
