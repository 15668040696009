/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import { Button, Grid } from '@material-ui/core';
import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';

import {
  Container,
  PageTitle,
  Section,
  SectionContent,
  SectionDescription,
} from './styles';
import Input from '../../../components/Input';
import getValidationErrors from '../../../utils/getValidationErrors';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import getOnlyNumbers from '../../../utils/getOnlyNumbers';
import InputMask from '../../../components/InputMask';

interface Address {
  cep: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
}

interface Cnpj {
  atividade_principal: {
    text: string;
    code: string;
  }[];
  nome: string;
  uf: string;
  telefone: string;
  atividades_secundarias: {
    text: string;
    code: string;
  }[];
  cep: string;
  bairro: string;
  logradouro: string;
  numero: string;
  municipio: string;
  porte: string;
  abertura: string;
  natureza_juridica: string;
  fantasia: string;
  cnpj: string;
  status: string;
  complemento: string;
  email: string;
}

const CreateClient: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [addressByZipCode, setAddressByZipCode] = useState<
    Address | undefined
  >();
  const history = useHistory();
  const { addToast } = useToast();

  React.useEffect(() => {
    document.title = 'SpotX - Cadastrar um cliente';
  }, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          nickname: Yup.string().required('Nome fantasia obrigatório'),
          cnpj: Yup.string().required('CNPJ é obrigatório'),
          email: Yup.string().email('Digite um e-mail válido'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('/clients', data);

        history.push('/clients/list');
        addToast({
          title: 'Cliente adicionado com sucesso!',
          type: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const error = err as AxiosError;
          addToast({
            type: 'error',
            title:
              error.response?.data.message ||
              'Não foi possível adicionar o cliente. Tente novamente mais tarde',
          });
        }
      }
    },
    [history, addToast],
  );

  const getCnpjPhone = useCallback((phone: string) => {
    if (phone.length > 16) {
      const [first] = phone.split('/');
      return getOnlyNumbers(first);
    }
    return getOnlyNumbers(phone);
  }, []);

  const handleSelectedCnpj = useCallback(
    async e => {
      try {
        const cnpj = getOnlyNumbers(String(e.target.value));
        formRef.current?.setFieldError('cnpj', '');
        if (cnpj) {
          const response = await api.get<Cnpj>(
            `/clients/cnpj/${getOnlyNumbers(cnpj)}`,
          );
          const { data } = response;

          formRef.current?.setData({
            name: data.nome,
            nickname: data.fantasia,
            main_activity: data.atividade_principal[0].text,
            email: data.email,
            phone: getCnpjPhone(data.telefone),
            zipcode: getOnlyNumbers(data.cep),
            address: data.logradouro,
            address_number: data.numero,
            district: data.bairro,
            city: data.municipio,
            state: data.uf,
          });
        }
      } catch (exp) {
        formRef.current?.setFieldError('cnpj', 'CNPJ inválido');
      }
    },
    [getCnpjPhone],
  );

  const handleSelectedZipCode = useCallback(async e => {
    try {
      const zipcode = String(e.target.value);
      formRef.current?.setFieldError('zipcode', '');
      if (zipcode) {
        const response = await api.get(
          `https://brasilapi.com.br/api/cep/v1/${getOnlyNumbers(zipcode)}`,
        );
        const { data } = response;
        setAddressByZipCode(data);

        formRef.current?.setData({
          address: data.street,
          district: data.neighborhood,
          city: data.city,
          state: data.state,
        });
      } else {
        setAddressByZipCode(undefined);
      }
    } catch (exp) {
      formRef.current?.setFieldError('zipcode', 'CEP inválido');
    }
  }, []);

  return (
    <Container>
      <PageTitle variant="h5">Cadastrar Cliente</PageTitle>

      <Form ref={formRef} onSubmit={handleSubmit} noValidate>
        <Section>
          <SectionDescription>Dados da empresa</SectionDescription>
          <SectionContent container spacing={2}>
            <Grid item sm={5}>
              <InputMask
                name="cnpj"
                mask="99.999.999/9999-99"
                label="CNPJ da empresa"
                required
                onBlur={handleSelectedCnpj}
              />
            </Grid>
            <Grid item sm={7}>
              <Input
                name="name"
                label="Nome da empresa"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <Input
                name="nickname"
                label="Nome fantasia"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid item sm={8}>
              <Input
                name="main_activity"
                label="Atividade principal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={8}>
              <Input
                name="email"
                type="email"
                label="E-mail"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <InputMask
                name="phone"
                mask="(99) 9 9999-9999"
                label="Telefone"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </SectionContent>
        </Section>

        <Section>
          <SectionDescription>Endereço</SectionDescription>
          <SectionContent container spacing={2}>
            <Grid item sm={4}>
              <InputMask
                name="zipcode"
                mask="99.999-999"
                label="CEP"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={handleSelectedZipCode}
              />
            </Grid>
            <Grid item sm={6}>
              <Input
                name="address"
                label="Endereço"
                defaultValue={addressByZipCode?.street}
                disabled={!addressByZipCode}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={2}>
              <Input
                type="number"
                name="address_number"
                label="Número"
                disabled={!addressByZipCode}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <Input
                name="district"
                label="Bairro"
                disabled={!addressByZipCode}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={5}>
              <Input
                name="city"
                label="Cidade"
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item sm={3}>
              <Input
                name="state"
                label="Estado"
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </SectionContent>
        </Section>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 20,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            Cadastrar
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default CreateClient;
