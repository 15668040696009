import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';

import { RouteType } from '../../../../services/dtos/types';
import AlertDialog, {
  AlertDialogHandles,
} from '../../../../components/AlertDialog';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import CreateRoute, { DialogHandles } from './CreateRoute';

interface RouteProps {
  motorist_id: string;
  routes?: RouteType[];
}

const getFrequencyType = (type: string) => {
  switch (type) {
    case 'daily':
      return 'por dia';
    case 'weekly':
      return 'por semana';
    case 'biweekly':
      return 'por quinzena';
    case 'monthly':
      return 'por mês';
    case 'annually':
      return 'por ano';

    default:
      return '';
  }
};

const Routes: React.FC<RouteProps> = ({ motorist_id, routes }) => {
  const createRouteRef = React.useRef<DialogHandles>(null);
  const alertDialogRef = React.useRef<AlertDialogHandles>(null);
  const routeIdRef = React.useRef<string | null>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const isRecurrent = React.useCallback((item: RouteType) => {
    if (typeof item.is_recurrent === 'boolean') {
      return item.is_recurrent ? 'Sim' : 'Não';
    }
    return '---';
  }, []);

  const frequency = React.useCallback((item: RouteType) => {
    let result = '---';
    if (item.frequency) {
      result = `${item.frequency}x`;
      if (item.frequency_type) {
        result = `${result} / ${getFrequencyType(item.frequency_type)}`;
      }
    }
    return result;
  }, []);

  const handleClickOpenDialogCreateRoute = React.useCallback(() => {
    createRouteRef.current?.openDialog();
  }, []);

  const handleDeleteRoute = React.useCallback((route_id: string) => {
    routeIdRef.current = route_id;
    alertDialogRef.current?.toggleDialog(true);
  }, []);

  const cancelDelete = React.useCallback(() => {
    alertDialogRef.current?.toggleDialog(false);
  }, []);

  const confirmDelete = React.useCallback(async () => {
    alertDialogRef.current?.toggleDialog(false);
    try {
      await api.delete(`/motorists/routes/${routeIdRef.current}`);
      addToast({
        type: 'success',
        title: 'Rota removida com sucesso!',
      });
      history.go(0);
    } catch (err) {
      const error = err as AxiosError;
      addToast({
        type: 'error',
        title:
          error.response?.data.message || 'Não foi possivel deletar a rota',
      });
    }
  }, [addToast, history]);

  return (
    <>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Origem</TableCell>
            <TableCell>Destino</TableCell>
            <TableCell>Rota recorrente?</TableCell>
            <TableCell colSpan={2}>Frequência</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {routes &&
            routes.map(item => (
              <TableRow key={item.id} hover>
                <TableCell>{item.origin_city.name}</TableCell>
                <TableCell>{item.destination_city.name}</TableCell>
                <TableCell>{isRecurrent(item)}</TableCell>
                <TableCell>{frequency(item)}</TableCell>
                <TableCell align="right">
                  <Button
                    color="secondary"
                    onClick={() => handleDeleteRoute(item.id)}
                  >
                    <DeleteOutlineIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClickOpenDialogCreateRoute}
        >
          Adicionar
        </Button>
      </div>

      <CreateRoute ref={createRouteRef} motorist_id={motorist_id} />
      <AlertDialog
        ref={alertDialogRef}
        alertTitle="Tem certeza que deseja excluir?"
        alertDescription="Tem certeza que deseja excluir esta rota?"
      >
        <Button onClick={cancelDelete} color="secondary">
          Cancelar
        </Button>
        <Button onClick={confirmDelete} color="primary">
          Excluir
        </Button>
      </AlertDialog>
    </>
  );
};

export default Routes;
