import React from 'react';
import { SnackbarProvider } from 'notistack';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';

const AppProvider: React.FC = ({ children }) => (
  <SnackbarProvider maxSnack={3}>
    <ToastProvider>
      <AuthProvider>{children}</AuthProvider>
    </ToastProvider>
  </SnackbarProvider>
);

export default AppProvider;
