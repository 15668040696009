import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../services/api';
import { MotoristType } from '../../../services/dtos/types';

import Address from './Address';
import Attachments from './Attachments';
import CNH from './CNH';
import Documents from './Documents';
import PersonalData from './PersonalData';
import Routes from './Routes';

import {
  Container,
  PageTitle,
  Section,
  SectionTitle,
  SectionContent,
} from './styles';
import Vehicle from './Vehicle';

interface RouteParams {
  id: string;
}

const ShowMotorist: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const [motorist, setMotorist] = React.useState({} as MotoristType);
  const history = useHistory();

  React.useEffect(() => {
    document.title = 'SpotX - Informações do motorista';
  }, []);

  React.useEffect(() => {
    const loadMotorist = async () => {
      const response = await api.get(`/motorists/${id}`);
      setMotorist(response.data);
    };

    loadMotorist();
  }, [id]);

  return (
    <Container>
      <PageTitle variant="h5">
        <Button style={{ marginRight: 16 }} onClick={() => history.goBack()}>
          <ArrowBack />
          Voltar
        </Button>
        Informações do motorista
      </PageTitle>

      <Section>
        <SectionTitle color="textSecondary">Dados pessoais</SectionTitle>
        <SectionContent>
          <PersonalData
            personalData={{
              motorist_id: id,
              name: motorist.name,
              phone: motorist.phone,
              email: motorist.email,
              birth: motorist.birth,
              gender: motorist.gender,
              marital_status: motorist.marital_status,
              birth_city: motorist.birth_city,
              birth_state: motorist.birth_state,
              collects_annual_iss: motorist.collects_annual_iss,
              school_degree: motorist.school_degree,
            }}
          />
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle color="textSecondary">Endereço</SectionTitle>
        <SectionContent>
          <Address
            address={{
              motorist_id: id,
              address: motorist.address,
              address_number: motorist.address_number
                ? Number(motorist.address_number)
                : undefined,
              district: motorist.district,
              city: motorist.city,
              zipcode: motorist.zipcode,
              state: motorist.state,
            }}
          />
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle color="textSecondary">Documentos</SectionTitle>
        <SectionContent>
          <Documents
            documents={{
              motorist_id: id,
              cpf: motorist.cpf,
              rg: motorist.rg,
              rg_dispatcher: motorist.rg_dispatcher,
              rg_uf: motorist.rg_uf,
              rg_dispatch_date: motorist.rg_dispatch_date,
              pis: motorist.pis,
            }}
          />
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle color="textSecondary">
          Carteira Nacional de Habilitação
        </SectionTitle>
        <SectionContent>
          <CNH driver_license={motorist.driver_license} />
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle color="textSecondary">Veículo</SectionTitle>
        <SectionContent>
          <Vehicle vehicle={motorist.vehicle} />
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle color="textSecondary">Rotas de preferência</SectionTitle>
        <SectionContent>
          <Routes motorist_id={id} routes={motorist.routes} />
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle color="textSecondary">Anexos</SectionTitle>
        <SectionContent>
          <Attachments motorist_id={id} attachments={motorist.attachments} />
        </SectionContent>
      </Section>
    </Container>
  );
};

export default ShowMotorist;
