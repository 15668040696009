import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Button } from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import InputStar from '../../../../components/InputStar';
import Input from '../../../../components/Input';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import getValidationErrors from '../../../../utils/getValidationErrors';

export interface RateFreightHandles {
  toggleDialog: (toggle: boolean) => void;
}

interface AlertDialogProps {
  freight_id: string;
  motorist_id: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps,
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RateFreight: React.RefForwardingComponent<
  RateFreightHandles,
  AlertDialogProps
> = ({ freight_id, motorist_id }, ref) => {
  const formRef = React.useRef<FormHandles>(null);
  const [open, setOpen] = React.useState(false);
  const { addToast } = useToast();
  const history = useHistory();

  const toggleDialog = React.useCallback((toggle: boolean) => {
    setOpen(toggle);
  }, []);

  React.useImperativeHandle(ref, () => {
    return {
      toggleDialog,
    };
  });

  const handleSubmit = React.useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          behavior_score: Yup.number().required('Campo obrigatório'),
          ponctuality_score: Yup.number().required('Campo obrigatório'),
          reliability_score: Yup.number().required('Campo obrigatório'),
          item_state_score: Yup.number().required('Campo obrigatório'),
          description: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('/freights/rate', {
          ...data,
          freight_id,
          motorist_id,
        });

        addToast({
          type: 'success',
          title: 'Frete avaliado com sucesso!',
        });

        await api.put(`/freights/${freight_id}/status`, { status: 'finished' });

        addToast({
          type: 'success',
          title: 'Frete finalizado com sucesso!',
        });

        setOpen(false);
        history.go(0);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const error = err as AxiosError;
          addToast({
            type: 'error',
            title:
              error.response?.data.message ||
              'Não foi possível avaliar o frete. Tente novamente mais tarde',
          });
        }
      }
    },
    [addToast, freight_id, motorist_id, history],
  );

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => toggleDialog(false)}
      aria-labelledby="alert-dialog-rate-title"
      aria-describedby="alert-dialog-rate-content"
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-rate-title">
          Avaliar frete do motorista
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-rate-content">
            <InputStar
              name="behavior_score"
              label="Como você avalia o atendimento do motorista?"
            />
            <InputStar
              name="ponctuality_score"
              label="Como você avalia a pontualidade do motorista?"
            />
            <InputStar
              name="reliability_score"
              label="Como você avalia a confiabilidade do frete?"
            />
            <InputStar
              name="item_state_score"
              label="Como você avalia a situação da carga na entrega do frete?"
            />
            <Input name="description" label="Observações (opcional)" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancelar
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Avaliar e Finalizar
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default React.forwardRef(RateFreight);
