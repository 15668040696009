/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import {
  RadioGroupProps,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio as MuiRadio,
  FormHelperText,
} from '@material-ui/core';
import { useField } from '@unform/core';

interface Option {
  label: string;
  value: string | number | boolean;
}

type AppRadioProps = RadioGroupProps & {
  name: string;
  options: Option[];
  label?: string;
  required?: boolean;
  disabled?: boolean;
};

const Radio: React.FC<AppRadioProps> = ({
  name,
  options,
  label,
  required,
  disabled,
  defaultValue: df,
  ...props
}) => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [value, setValue] = React.useState<string | number | boolean>('');

  const radioGroupDefaultValue = React.useMemo(() => {
    if (defaultValue) return defaultValue;
    if (df) return df;
    return undefined;
  }, [defaultValue, df]);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue() {
        if (value !== '') {
          return value;
        }
        if (radioGroupDefaultValue === 'true') return true;
        if (radioGroupDefaultValue === 'false') return false;
        return radioGroupDefaultValue;
      },
    });
  }, [registerField, fieldName, value, radioGroupDefaultValue]);

  return (
    <FormControl component="fieldset" error={!!error} required={required}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup
        aria-label={name}
        name={name}
        defaultValue={String(radioGroupDefaultValue)}
        {...props}
      >
        {options.map((item, index) => (
          <FormControlLabel
            key={index}
            value={String(item.value)}
            control={<MuiRadio color="primary" />}
            label={item.label}
            onClick={() => {
              const selectedValue = item.value;
              const isnum = /^\d+$/.test(String(selectedValue));
              if (selectedValue === 'true') setValue(true);
              else if (selectedValue === 'false') setValue(false);
              else if (isnum) setValue(Number(selectedValue));
              else setValue(selectedValue);
            }}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default React.memo(Radio);
