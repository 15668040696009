/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useRef } from 'react';
import { TextField, BaseTextFieldProps } from '@material-ui/core';
import MuiAutocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from '@material-ui/lab/Autocomplete';
import { useField } from '@unform/core';
import { FilterOptionsState } from '@material-ui/lab';

interface Option {
  label: string;
  value: string | number;
}

interface AppAutocompleteProps extends BaseTextFieldProps {
  name: string;
  options: Option[];
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Option>,
  ) => void;
  onInputChange?: (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => void;
  filterOptions?: (
    options: Option[],
    sate: FilterOptionsState<Option>,
  ) => Option[];
}

const Autocomplete: React.FC<AppAutocompleteProps> = ({
  name,
  options,
  disabled,
  variant = 'outlined',
  onChange,
  onInputChange,
  filterOptions,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      // path: 'value',
      getValue(ref: HTMLInputElement) {
        const { value } = ref;
        if (value.length > 0) {
          const option = options.find(item => item.label === value);

          if (option) {
            return String(option.value);
          }
          return value;
        }
        return undefined;
      },
    });
  }, [registerField, fieldName, options]);

  const selectedValue = useMemo(() => {
    return options.find(item => item.value === defaultValue);
  }, [options, defaultValue]);

  return (
    <MuiAutocomplete
      noOptionsText="Não encontrado"
      filterOptions={filterOptions}
      options={options}
      getOptionLabel={option => option.label}
      defaultValue={selectedValue}
      disabled={!!disabled}
      renderInput={params => (
        <TextField
          style={{ backgroundColor: '#fff' }}
          inputRef={inputRef}
          name={name}
          {...params}
          {...props}
          helperText={error}
          error={!!error}
          variant={variant}
        />
      )}
      onChange={onChange}
      onInputChange={onInputChange}
    />
  );
};

export default React.memo(Autocomplete);
