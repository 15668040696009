import React from 'react';
import { useParams } from 'react-router-dom';

import api from '../../../services/api';
import { ExpenseType } from '../../../services/dtos/types';
import ExpenseData from './ExpenseData';

import {
  Container,
  PageTitle,
  Section,
  SectionTitle,
  SectionContent,
} from './styles';

interface RequestParams {
  id: string;
}

const ShowExpense: React.FC = () => {
  const { id } = useParams<RequestParams>();
  const [expense, setExpense] = React.useState<ExpenseType>({} as ExpenseType);

  React.useEffect(() => {
    document.title = 'SpotX - Informações da despesa';
  }, []);

  React.useEffect(() => {
    const loadExpense = async () => {
      const response = await api.get(`/expenses/${id}`);
      setExpense(response.data);
    };

    loadExpense();
  }, [id]);

  return (
    <Container>
      <PageTitle variant="h5">Informações da despesa</PageTitle>

      <Section>
        <SectionTitle color="textSecondary">Dados da despesa</SectionTitle>
        <SectionContent>
          <ExpenseData expense={expense} />
        </SectionContent>
      </Section>
    </Container>
  );
};

export default ShowExpense;
