import React from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { useStyles } from './styles';
import { useLayout } from '..';
import { useAuth } from '../../hooks/auth';

const NavBar: React.FC = () => {
  const classes = useStyles();
  const { openSideMenu, toggleOpenSideMenu } = useLayout();
  const { signOut } = useAuth();

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, openSideMenu && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleOpenSideMenu}
          className={clsx(
            classes.menuButton,
            openSideMenu && classes.menuButtonHidden,
          )}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          Painel SpotX
        </Typography>
        <IconButton color="inherit" onClick={signOut}>
          <PowerSettingsNewIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
