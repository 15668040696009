import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosError } from 'axios';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';
import { VehicleType } from '../../../../services/dtos/types';
import { useToast } from '../../../../hooks/toast';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Input from '../../../../components/Input';
import InputMask from '../../../../components/InputMask';
import Radio from '../../../../components/Radio';
import api from '../../../../services/api';
import Autocomplete from '../../../../components/Autocomplete';

interface OptionType {
  label: string;
  value: string;
}

interface CategoryAndBodyType {
  id: string;
  name: string;
  type: string;
}

interface VehicleProps {
  vehicle?: VehicleType;
}

const Vehicle: React.FC<VehicleProps> = ({ vehicle }) => {
  const formRef = React.useRef<FormHandles>(null);
  const [editMode, setEditMode] = React.useState(false);
  const [categories, setCategories] = React.useState<OptionType[]>([]);
  const [bodies, setBodies] = React.useState<OptionType[]>([]);
  const { addToast } = useToast();
  const history = useHistory();

  React.useEffect(() => {
    const loadCategories = async () => {
      const response = await api.get<CategoryAndBodyType[]>(
        '/vehicles/categories/search',
      );
      const formattedData = response.data.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setCategories(formattedData);
    };

    const loadBodies = async () => {
      const response = await api.get<CategoryAndBodyType[]>(
        '/vehicles/bodies/search',
      );
      const formattedData = response.data.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setBodies(formattedData);
    };

    loadCategories();
    loadBodies();
  }, []);

  const handleSubmit = React.useCallback(
    async data => {
      if (!vehicle) return;
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          license_plate: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        await api.put(`/vehicles/${vehicle.id}`, data);
        history.go(0);

        addToast({
          type: 'success',
          title: 'Veículo alterado com sucesso!',
        });
        setEditMode(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const error = err as AxiosError;
          addToast({
            type: 'error',
            title:
              error.response?.data.message ||
              'Não foi possível atualizar o veículo. Tente novamente mais tarde',
          });
        }
      }
    },
    [addToast, vehicle, history],
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={vehicle}>
      <Table>
        <TableBody>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Categoria
            </TableCell>
            <TableCell>
              {editMode && (
                <Autocomplete name="vehicle_category_id" options={categories} />
              )}
              {!editMode && vehicle?.vehicle_category?.name}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Carroceria
            </TableCell>
            <TableCell>
              {editMode && (
                <Autocomplete name="vehicle_body_id" options={bodies} />
              )}
              {!editMode && vehicle?.vehicle_body?.name}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Placa
            </TableCell>
            <TableCell>
              {editMode && <InputMask name="license_plate" mask="aaa****" />}
              {!editMode && vehicle?.license_plate}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              UF
            </TableCell>
            <TableCell>
              {editMode && <InputMask name="license_uf" mask="aa" />}
              {!editMode && vehicle?.license_uf}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Marca
            </TableCell>
            <TableCell>
              {editMode && <Input name="brand" />}
              {!editMode && vehicle?.brand}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Modelo
            </TableCell>
            <TableCell>
              {editMode && <Input name="model" />}
              {!editMode && vehicle?.model}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Ano de fabricação
            </TableCell>
            <TableCell>
              {editMode && <InputMask name="release_year" mask="9999" />}
              {!editMode && vehicle?.release_year}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              renavam
            </TableCell>
            <TableCell>
              {editMode && <InputMask name="renavam" mask="99999999999" />}
              {!editMode && vehicle?.renavam}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              chassi
            </TableCell>
            <TableCell>
              {editMode && <Input name="chassi" />}
              {!editMode && vehicle?.chassi}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              ANTT
            </TableCell>
            <TableCell>
              {editMode && <Input name="antt" />}
              {!editMode && vehicle?.antt}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Capacidade (M³)
            </TableCell>
            <TableCell>
              {editMode && <Input name="capacity_m3" type="number" />}
              {!editMode && vehicle?.capacity_m3}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Capacidade (TARA)
            </TableCell>
            <TableCell>
              {editMode && <Input name="capacity_tara" type="number" />}
              {!editMode && vehicle?.capacity_tara}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Capacidade (Kg)
            </TableCell>
            <TableCell>
              {editMode && <Input name="capacity_kg" type="number" />}
              {!editMode && vehicle?.capacity_kg}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Tem rastreador?
            </TableCell>
            <TableCell>
              {editMode && (
                <Radio
                  row
                  name="has_tracker"
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                />
              )}
              {!editMode && (vehicle?.has_tracker ? 'Sim' : 'Não')}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Tem seguro?
            </TableCell>
            <TableCell>
              {editMode && (
                <Radio
                  row
                  name="has_insurance"
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                />
              )}
              {!editMode && (vehicle?.has_insurance ? 'Sim' : 'Não')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}
      >
        {!editMode && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setEditMode(true)}
          >
            Editar
          </Button>
        )}
        {editMode && (
          <div style={{ marginLeft: 16 }}>
            <Button color="secondary" onClick={() => setEditMode(false)}>
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: 16 }}
            >
              Salvar
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

export default Vehicle;
