import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import Autocomplete from '../../../components/Autocomplete';
import AutocompleteGeo from '../../../components/AutocompleteGeo';
import Input from '../../../components/Input';
import Radio from '../../../components/Radio';
import getValidationErrors from '../../../utils/getValidationErrors';

import { Container, PageTitle } from './styles';
import {
  ClientType,
  ItemCategoryType,
  ItemType,
} from '../../../services/dtos/types';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import InputNumberMask from '../../../components/InputNumberMask';
import MultipleSelect from '../../../components/MultipleSelect';
import Select from '../../../components/Select';

interface OptionType {
  label: string;
  value: string;
}

const CreateFreight: React.FC = () => {
  const formRef = React.useRef<FormHandles>(null);
  const [clients, setClients] = React.useState<OptionType[]>([]);
  const [itemCategories, setItemCategories] = React.useState<OptionType[]>([]);
  const [vehicleCategories, setVehicleCategories] = React.useState<
    OptionType[]
  >([]);
  const [vehicleBodies, setVehicleBodies] = React.useState<OptionType[]>([]);
  const [showItemUnitsForm, setShowItemUnitsForm] = React.useState(false);
  const { addToast } = useToast();
  const history = useHistory();

  React.useEffect(() => {
    document.title = 'SpotX - Cadastrar frete';
  }, []);

  React.useEffect(() => {
    const searchItemCategories = async () => {
      const response = await api.get<ItemCategoryType[]>(
        `/freights/items/categories`,
      );
      const formattedData = response.data.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setItemCategories(formattedData);
    };

    const searchVehicleCategories = async () => {
      const response = await api.get<ItemCategoryType[]>(
        `/vehicles/categories/search`,
      );
      const formattedData = response.data.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setVehicleCategories(formattedData);
    };

    const searchVehicleBodies = async () => {
      const response = await api.get<ItemCategoryType[]>(
        `/vehicles/bodies/search`,
      );
      const formattedData = response.data.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setVehicleBodies(formattedData);
    };

    searchItemCategories();
    searchVehicleCategories();
    searchVehicleBodies();
  }, []);

  const handleSearchRequester = React.useCallback(
    (e, value: string, reason) => {
      if (value.length < 3) return;
      if (reason === 'input') {
        const search = async () => {
          const response = await api.get<ClientType[]>(
            `/clients/search?name=${value}`,
          );
          const formattedData = response.data.map(item => {
            return {
              label: `${item.nickname}`,
              value: item.id,
            };
          });
          setClients(formattedData);
        };

        setTimeout(() => {
          search();
        }, 500);
      }
    },
    [],
  );

  const handleSubmit = React.useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const itemData = data.item as ItemType;
        const vehicle_categories = data.vehicle_categories as OptionType[];
        const vehicle_bodies = data.vehicle_bodies as OptionType[];
        const publish_on_fretebras = data.publish_on_fretebras as boolean;

        const schema = Yup.object().shape({
          type: Yup.string()
            .equals(['terrestrial', 'aerial', 'hotline'])
            .required('Campo obrigatório'),
          origin_id: Yup.number().required('Campo obrigatório'),
          destination_id: Yup.number().required('Campo obrigatório'),
          requester_id: Yup.string().uuid().required('Campo obrigatório'),
          received_at: Yup.date(),
          suggested_price: Yup.number().required('Campo obrigatório'),
          suggested_price_type: Yup.string().required('Campo obrigatório'),
          has_toll: Yup.boolean().required('Campo obrigatório'),
          is_urgent: Yup.boolean(),
          item: Yup.object().shape({
            name: Yup.string().required('Campo obrigatório'),
            item_category_id: Yup.string().required('Campo obrigatório'),
            require_tracker: Yup.boolean().required('Escolha uma opção'),
            has_complement: Yup.boolean().required('Escolha uma opção'),
          }),
          vehicle_categories: Yup.array()
            .of(
              Yup.object().shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              }),
            )
            .required('Escolha pelo menos uma opção'),
          vehicle_bodies: Yup.array()
            .of(
              Yup.object().shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              }),
            )
            .required('Escolha pelo menos uma opção'),
        });

        await schema.validate(data, { abortEarly: false });

        delete data.item;
        delete data.vehicle_categories;
        delete data.vehicle_bodies;
        delete data.publish_on_fretebras;

        const freight = await api.post('/freights', data);

        await api.post('/freights/items', {
          ...itemData,
          freight_id: freight.data.id,
        });

        await api.post(
          'freights/set-vehicle-categories-and-bodies-to-freight',
          {
            freight_id: freight.data.id,
            vehicle_categories: vehicle_categories.map(i => i.value),
            vehicle_bodies: vehicle_bodies.map(i => i.value),
          },
        );

        if (publish_on_fretebras) {
          await api.get(`/fretebras/sync/${freight.data.id}`);
        }

        addToast({
          type: 'success',
          title: 'Frete cadastrado com sucesso!',
        });
        history.push('/freights/list');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const error = err as AxiosError;
          addToast({
            type: 'error',
            title:
              error.response?.data.message ||
              'Não foi possível cadastrar o frete. Tente novamente mais tarde',
          });
        }
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <PageTitle variant="h5">Cadastro de frete</PageTitle>

      <Form ref={formRef} onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item sm={7} md={8}>
            <Autocomplete
              name="requester_id"
              variant="outlined"
              label="Solicitante"
              required
              options={clients}
              onInputChange={handleSearchRequester}
              filterOptions={op => op}
            />
          </Grid>
          <Grid item sm={5} md={4}>
            <Input
              name="received_at"
              type="datetime-local"
              variant="outlined"
              label="Cotação solicitada em"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={6}>
            <AutocompleteGeo
              name="origin_id"
              registerOnlyId
              label="Origem"
              required
            />
          </Grid>
          <Grid item sm={6}>
            <AutocompleteGeo
              name="destination_id"
              registerOnlyId
              label="Destino"
              required
            />
          </Grid>
          <Grid item sm={6}>
            <Select
              name="type"
              label="Tipo de frete"
              options={[
                { label: 'Terrestre', value: 'terrestrial' },
                { label: 'Aéreo', value: 'aerial' },
                { label: 'Hot-line', value: 'hotline' },
              ]}
              defaultValue="terrestrial"
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ fontSize: 18, color: '#999', marginTop: 16 }}
          >
            Dados da carga
          </Grid>
          <Scope path="item">
            <Grid item sm={6}>
              <Input
                name="name"
                variant="outlined"
                label="Produto (Carga)"
                required
              />
            </Grid>
            <Grid item sm={6}>
              <Autocomplete
                name="item_category_id"
                variant="outlined"
                label="Espécie"
                options={itemCategories}
                onChange={(event, value, reason) => {
                  if (reason === 'select-option') {
                    if (value.label === 'Unidades') {
                      setShowItemUnitsForm(true);
                    }
                  }
                  if (reason === 'clear') {
                    setShowItemUnitsForm(false);
                  }
                }}
              />
            </Grid>
            {showItemUnitsForm && (
              <>
                <Grid item sm={4}>
                  <Input
                    type="number"
                    name="width"
                    variant="outlined"
                    label="Largura (cm)"
                  />
                </Grid>
                <Grid item sm={4}>
                  <Input
                    type="number"
                    name="height"
                    variant="outlined"
                    label="Altura (cm)"
                  />
                </Grid>
                <Grid item sm={4}>
                  <Input
                    type="number"
                    name="depth"
                    variant="outlined"
                    label="Profundidade (cm)"
                  />
                </Grid>
              </>
            )}

            <Grid item sm={6}>
              <Input
                type="number"
                name="weight"
                variant="outlined"
                label="Peso (Kg)"
              />
            </Grid>
            <Grid item sm={6}>
              <InputNumberMask
                name="value"
                label="Valor (nota fiscal)"
                numberFormat={{ prefix: 'R$ ' }}
              />
            </Grid>
            <Grid item sm={6}>
              <Radio
                row
                name="require_tracker"
                label="Exige rastreamento?"
                options={[
                  { label: 'Sim', value: true },
                  { label: 'Não', value: false },
                ]}
              />
            </Grid>
            <Grid item sm={6}>
              <Radio
                row
                name="has_complement"
                label="Complemento?"
                defaultValue="false"
                options={[
                  { label: 'Sim', value: true },
                  { label: 'Não', value: false },
                ]}
              />
            </Grid>
          </Scope>
          <Grid
            item
            xs={12}
            style={{ fontSize: 18, color: '#999', marginTop: 16 }}
          >
            Veículo
          </Grid>
          <Grid item sm={6}>
            <MultipleSelect
              name="vehicle_categories"
              options={vehicleCategories}
              label="Tipo"
              placeholder="Veículos"
              max={4}
            />
          </Grid>
          <Grid item sm={6}>
            <MultipleSelect
              name="vehicle_bodies"
              options={vehicleBodies}
              label="Carroceria"
              placeholder="Carrocerias"
              max={4}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ fontSize: 18, color: '#999', marginTop: 16 }}
          >
            Informações adicionais do frete
          </Grid>
          <Grid item sm={4}>
            <InputNumberMask
              label="Preço ideal"
              name="suggested_price"
              numberFormat={{ prefix: 'R$ ' }}
            />
          </Grid>
          <Grid item sm={4}>
            <Select
              label="Selecione um tipo"
              name="suggested_price_type"
              options={[
                { label: 'Por tonelada', value: 'per_ton' },
                { label: 'Cheio', value: 'full' },
                { label: 'A combinar', value: 'to_match' },
                { label: 'Por kilômetro', value: 'per_kilometer' },
              ]}
            />
          </Grid>
          <Grid item sm={4}>
            <Radio
              row
              label="Incluso pedágio?"
              name="has_toll"
              defaultValue="false"
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />
          </Grid>
          <Grid item sm={12}>
            <Input
              name="description"
              variant="outlined"
              label="Informações adicionais do embarque"
            />
          </Grid>
          <Grid item sm={6}>
            <Radio
              row
              label="Publicar no Fretebras?"
              name="publish_on_fretebras"
              defaultValue="true"
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />
          </Grid>
          <Grid item sm={6}>
            <Radio
              row
              label="É urgente?"
              name="is_urgent"
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 20,
            marginTop: 20,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            Cadastrar
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default CreateFreight;
