import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { differenceInYears, format, parseISO } from 'date-fns';

import { FormHandles } from '@unform/core';
import { AxiosError } from 'axios';
import Input from '../../../../components/Input';
import { useToast } from '../../../../hooks/toast';
import getValidationErrors from '../../../../utils/getValidationErrors';
import api from '../../../../services/api';
import Select from '../../../../components/Select';
import Autocomplete from '../../../../components/Autocomplete';
import Radio from '../../../../components/Radio';
import InputMask from '../../../../components/InputMask';

interface State {
  id: number;
  sigla: string;
  nome: string;
}

interface City {
  id: number;
  nome: string;
}

interface Option {
  id: number;
  label: string;
  value: string | number;
}

interface IPersonalData {
  motorist_id: string;
  name?: string;
  phone?: string;
  email?: string;
  gender?: string;
  birth?: Date;
  marital_status?: string;
  school_degree?: string;
  birth_city?: string;
  birth_state?: string;
  collects_annual_iss?: boolean;
}

interface PersonalDataProps {
  personalData: IPersonalData;
}

const PersonalData: React.FC<PersonalDataProps> = ({ personalData }) => {
  const formRef = React.useRef<FormHandles>(null);
  const [editMode, setEditMode] = React.useState(false);
  const [states, setStates] = React.useState<State[]>([]);
  const [cities, setCities] = React.useState<City[]>([]);
  const [selectedBirthState, setSelectedBirthState] = React.useState(
    {} as Option,
  );
  const { addToast } = useToast();

  React.useEffect(() => {
    const loadStates = async () => {
      const response = await api.get(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome',
      );
      setStates(response.data);
    };

    loadStates();
  }, []);

  const handleSubmit = React.useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        const { birth } = data;
        data.birth = birth ? `${birth}T00:00:00` : undefined;

        const schema = Yup.object().shape({
          name: Yup.string().required('Campo obrigatório'),
          phone: Yup.string().required('Campo obrigatório'),
          email: Yup.string().email('Email inválido'),
          gender: Yup.string(),
          birth: Yup.date(),
          marital_status: Yup.string(),
          school_degree: Yup.string(),
          birth_city: Yup.string(),
          collect_annual_iss: Yup.boolean(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put(
          `/motorists/${personalData.motorist_id}`,
          data,
        );
        Object.assign(personalData, { ...response.data, birth });

        addToast({
          type: 'success',
          title: 'Informações pessoais alteradas com sucesso!',
        });
        setEditMode(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const error = err as AxiosError;
          addToast({
            type: 'error',
            title:
              error.response?.data.message ||
              'Não foi possível alterar as informações. Tente novamente mais tarde',
          });
        }
      }
    },
    [addToast, personalData],
  );

  const handleSelectBirthState = React.useCallback(async (e, value: Option) => {
    if (value) {
      setSelectedBirthState(value);
      const response = await api.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value.id}/municipios`,
      );
      setCities(response.data);
    } else {
      setSelectedBirthState({} as Option);
      setCities([]);
    }
  }, []);

  const motoristAge = React.useMemo(() => {
    if (personalData.birth) {
      const age = differenceInYears(new Date(), new Date(personalData?.birth));
      return `${age} anos`;
    }
    return undefined;
  }, [personalData.birth]);

  const formattedMotoristBirth = React.useMemo(() => {
    if (personalData.birth) {
      const birth = parseISO(String(personalData.birth));
      return format(birth, 'dd/MM/yyyy');
    }
    return undefined;
  }, [personalData.birth]);

  const formattedStates = React.useMemo(() => {
    return states.map(state => {
      return {
        id: state.id,
        label: state.nome,
        value: state.sigla,
      };
    });
  }, [states]);

  const formattedCities = React.useMemo(() => {
    return cities.map(city => {
      return {
        id: city.id,
        label: city.nome,
        value: city.nome,
      };
    });
  }, [cities]);

  const formattedGender = React.useMemo(() => {
    if (personalData.gender === 'M') return 'Masculino';
    if (personalData.gender === 'F') return 'Feminino';
    return '';
  }, [personalData.gender]);

  const formattedMaritalStatus = React.useMemo(() => {
    if (personalData.marital_status === 'single') return 'Solteiro';
    if (personalData.marital_status === 'married') return 'Casado';
    if (personalData.marital_status === 'divorced') return 'Divorciado';
    if (personalData.marital_status === 'widow') return 'Viúvo';
    return personalData.marital_status;
  }, [personalData.marital_status]);

  const formattedSchoolDegree = React.useMemo(() => {
    if (personalData.school_degree === 'incomplete_basic_school')
      return 'Ensino fundamental incompleto';
    if (personalData.school_degree === 'basic_school')
      return 'Ensino fundamental completo';
    if (personalData.school_degree === 'incomplete_high_school')
      return 'Ensino médio incompleto';
    if (personalData.school_degree === 'high_school')
      return 'Ensino médio completo';
    if (personalData.school_degree === 'incomplete_graduate')
      return 'Graduação incompleta';
    if (personalData.school_degree === 'graduate') return 'Graduação completa';
    if (personalData.school_degree === 'postgraduate') return 'Pós graduação';
    if (personalData.school_degree === 'doctorate') return 'Doutorado';
    if (personalData.school_degree === 'other') return 'Outro';
    return personalData.school_degree;
  }, [personalData.school_degree]);

  const formattedCollectsAnnualIss = React.useMemo(() => {
    if (personalData.collects_annual_iss === true) return 'Sim';
    if (personalData.collects_annual_iss === false) return 'Não';
    return personalData.collects_annual_iss;
  }, [personalData.collects_annual_iss]);

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={personalData}>
      <Table>
        <TableBody>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Nome completo
            </TableCell>
            <TableCell>
              {editMode && <Input name="name" />}
              {!editMode && personalData.name}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Telefone
            </TableCell>
            <TableCell>
              {editMode && <InputMask name="phone" mask="(99) 9 9999-9999" />}
              {!editMode && personalData.phone}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              E-mail
            </TableCell>
            <TableCell>
              {editMode && <Input name="email" />}
              {!editMode && personalData.email}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Gênero
            </TableCell>
            <TableCell>
              {editMode && (
                <Radio
                  row
                  name="gender"
                  options={[
                    { label: 'Masculino', value: 'M' },
                    { label: 'Feminino', value: 'F' },
                  ]}
                />
              )}
              {!editMode && formattedGender}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Nascimento
            </TableCell>
            <TableCell>
              {editMode && <Input name="birth" type="date" />}
              {!editMode && formattedMotoristBirth}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Idade
            </TableCell>
            <TableCell>{motoristAge}</TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Estado Civil
            </TableCell>
            <TableCell>
              {editMode && (
                <Select
                  name="marital_status"
                  options={[
                    { label: 'Opção', value: '' },
                    { label: 'Solteiro(a)', value: 'single' },
                    { label: 'Casado(a)', value: 'married' },
                    { label: 'Divorciado(a)', value: 'divorced' },
                    { label: 'Viúvo(a)', value: 'widow' },
                  ]}
                />
              )}
              {!editMode && formattedMaritalStatus}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Escolaridade
            </TableCell>
            <TableCell>
              {editMode && (
                <Select
                  name="school_degree"
                  options={[
                    { label: 'Opção', value: '' },
                    {
                      label: 'Ensino fundamental incompleto',
                      value: 'incomplete_basic_school',
                    },
                    {
                      label: 'Ensino fundamental completo',
                      value: 'basic_school',
                    },
                    {
                      label: 'Ensino médio incompleto',
                      value: 'incomplete_high_school',
                    },
                    { label: 'Ensino médio completo', value: 'high_school' },
                    {
                      label: 'Graduação incompleta',
                      value: 'incomplete_graduate',
                    },
                    { label: 'Graduação completa', value: 'graduate' },
                    { label: 'Pós graduação', value: 'postgraduate' },
                    { label: 'Doutorado', value: 'doctorate' },
                    { label: 'Outro', value: 'other' },
                  ]}
                />
              )}
              {!editMode && formattedSchoolDegree}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Onde nasceu
            </TableCell>
            <TableCell>
              {editMode && !personalData.birth_city && (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Autocomplete
                    style={{ minWidth: 250 }}
                    name="birth_state"
                    label="Estado"
                    options={formattedStates}
                    onChange={handleSelectBirthState}
                  />
                  <Autocomplete
                    style={{ minWidth: 350 }}
                    name="birth_city"
                    label="Cidade onde nasceu"
                    options={formattedCities}
                    disabled={!selectedBirthState.id}
                  />
                </div>
              )}
              {!editMode &&
                `${personalData.birth_city}-${personalData.birth_state}`}
              {editMode &&
                personalData.birth_city &&
                `${personalData.birth_city}-${personalData.birth_state}`}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Recolhe ISS anual?
            </TableCell>
            <TableCell>
              {editMode && (
                <Radio
                  row
                  name="collects_annual_iss"
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                />
              )}
              {!editMode && formattedCollectsAnnualIss}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}
      >
        {!editMode && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setEditMode(true)}
          >
            Editar
          </Button>
        )}
        {editMode && (
          <div style={{ marginLeft: 16 }}>
            <Button color="secondary" onClick={() => setEditMode(false)}>
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: 16 }}
            >
              Salvar
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

export default PersonalData;
