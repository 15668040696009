import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { AxiosError } from 'axios';
import Input from '../../../../components/Input';
import { useToast } from '../../../../hooks/toast';
import getValidationErrors from '../../../../utils/getValidationErrors';
import api from '../../../../services/api';
import getOnlyNumbers from '../../../../utils/getOnlyNumbers';
import InputMask from '../../../../components/InputMask';

interface IAddress {
  id: string;
  address?: string;
  address_number?: number;
  district?: string;
  city?: string;
  zipcode?: string;
  state?: string;
}

interface AddressProps {
  address: IAddress;
}

const Address: React.FC<AddressProps> = ({ address }) => {
  const formRef = React.useRef<FormHandles>(null);
  const [editMode, setEditMode] = React.useState(false);
  const { addToast } = useToast();

  const handleSubmit = React.useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          address: Yup.string().required(),
          address_number: Yup.string(),
          district: Yup.string().required(),
          city: Yup.string().required(),
          zipcode: Yup.string().required(),
          state: Yup.string().required(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put(`/clients/${address.id}`, data);
        Object.assign(address, response.data);

        addToast({
          type: 'success',
          title: 'Endereço alterado com sucesso!',
        });
        setEditMode(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const error = err as AxiosError;
          addToast({
            type: 'error',
            title:
              error.response?.data.message ||
              'Não foi possível salvar o endereço. Tente novamente mais tarde',
          });
        }
      }
    },
    [addToast, address],
  );

  const handleSelectedZipCode = React.useCallback(async e => {
    try {
      const zipcode = String(e.target.value);
      formRef.current?.setFieldError('zipcode', '');
      const { data } = await api.get(
        `https://brasilapi.com.br/api/cep/v1/${getOnlyNumbers(zipcode)}`,
      );

      formRef.current?.setData({
        address: data.street,
        district: data.neighborhood,
        city: data.city,
        state: data.state,
      });
    } catch (exp) {
      formRef.current?.setFieldError('zipcode', 'CEP inválido');
    }
  }, []);

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={address}>
      <Table>
        <TableBody>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              CEP
            </TableCell>
            <TableCell>
              {editMode && (
                <InputMask
                  name="zipcode"
                  mask="99.999-999"
                  onBlur={handleSelectedZipCode}
                />
              )}
              {!editMode && address.zipcode}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Rua
            </TableCell>
            <TableCell>
              {editMode && <Input name="address" />}
              {!editMode && address.address}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Número
            </TableCell>
            <TableCell>
              {editMode && <Input name="address_number" type="number" />}
              {!editMode && address.address_number}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Bairro
            </TableCell>
            <TableCell>
              {editMode && <Input name="district" />}
              {!editMode && address.district}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Cidade
            </TableCell>
            <TableCell>
              {editMode && <Input name="city" disabled />}
              {!editMode && address.city}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Estado
            </TableCell>
            <TableCell>
              {editMode && <Input name="state" disabled />}
              {!editMode && address.state}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}
      >
        {!editMode && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setEditMode(true)}
          >
            Editar
          </Button>
        )}
        {editMode && (
          <div style={{ marginLeft: 16 }}>
            <Button color="secondary" onClick={() => setEditMode(false)}>
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: 16 }}
            >
              Salvar
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

export default Address;
