import { Grid } from '@material-ui/core';
import { FormHandles } from '@unform/core';
import React from 'react';
import Autocomplete from '../../../../components/Autocomplete';
import AutocompleteGeo from '../../../../components/AutocompleteGeo';

import Input from '../../../../components/Input';
import InputNumberMask from '../../../../components/InputNumberMask';
import Select from '../../../../components/Select';
import api from '../../../../services/api';

import { Container, Title, Content, Footer } from './styles';

interface OptionType {
  label: string;
  value: string;
}

interface CategoryAndBodyType {
  id: string;
  name: string;
  type: string;
}

interface FilterProps {
  renderAction: () => React.ReactNode;
}

const FilterMotorists: React.ForwardRefRenderFunction<
  FormHandles,
  FilterProps
> = ({ renderAction }, ref) => {
  const [categories, setCategories] = React.useState<OptionType[]>([]);
  const [bodies, setBodies] = React.useState<OptionType[]>([]);

  React.useEffect(() => {
    const loadCategories = async () => {
      const response = await api.get<CategoryAndBodyType[]>(
        '/vehicles/categories/search',
      );
      const formattedData = response.data.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setCategories(formattedData);
    };

    const loadBodies = async () => {
      const response = await api.get<CategoryAndBodyType[]>(
        '/vehicles/bodies/search',
      );
      const formattedData = response.data.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setBodies(formattedData);
    };

    loadCategories();
    loadBodies();
  }, []);

  return (
    <Container ref={ref} onSubmit={data => data}>
      <Title>Filtros</Title>
      <Content container spacing={1}>
        <Grid item xs={6} sm={3}>
          <Select
            name="type"
            label="Tipo de motorista"
            margin="dense"
            options={[
              { label: 'Todos', value: 0 },
              { label: 'Pessoa Física', value: 1 },
              { label: 'Pessoa Jurídica', value: 2 },
            ]}
            defaultValue={0}
          />
        </Grid>
        <Grid item xs={6} sm={5}>
          <Input name="name" label="Nome do motorista" margin="dense" />
        </Grid>
        <Grid item xs={6} sm={4}>
          <AutocompleteGeo
            name="checkin"
            label="Cidade checkin"
            margin="dense"
          />
        </Grid>
        <Grid item xs={8} sm={4}>
          <AutocompleteGeo name="origin" label="Origem" margin="dense" />
        </Grid>
        <Grid item xs={4} sm={2}>
          <InputNumberMask
            name="origin_radius"
            label="Raio"
            margin="dense"
            numberFormat={{ suffix: ' Km' }}
          />
        </Grid>
        <Grid item xs={8} sm={4}>
          <AutocompleteGeo name="destination" label="Destino" margin="dense" />
        </Grid>
        <Grid item xs={4} sm={2}>
          <InputNumberMask
            name="destination_radius"
            label="Raio"
            margin="dense"
            numberFormat={{ suffix: ' Km' }}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            name="vehicle_categories"
            label="Veículo"
            margin="dense"
            options={categories}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            name="vehicle_bodies"
            label="Carroceria"
            margin="dense"
            options={bodies}
          />
        </Grid>
      </Content>
      <Footer>{renderAction()}</Footer>
    </Container>
  );
};

export default React.forwardRef(FilterMotorists);
