import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import RvHookupIcon from '@material-ui/icons/RvHookup';

import { Link } from 'react-router-dom';
import api from '../../../services/api';
import { ExpenseType, PaginateListType } from '../../../services/dtos/types';
import { Container, PageTitle } from './styles';
import parseCurrency from '../../../utils/parseCurrency';

const ListExpenses: React.FC = () => {
  const [expenses, setExpenses] = useState<PaginateListType<ExpenseType>>({
    current_page: 0,
    from: 1,
    to: 15,
    per_page: 15,
    total: 15,
    data: [],
  } as PaginateListType<ExpenseType>);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(15);

  React.useEffect(() => {
    document.title = 'SpotX - Todas as despesas';
  }, []);

  useEffect(() => {
    api
      .get('expenses', { params: { page: page + 1, per_page: perPage } })
      .then(response => {
        setExpenses(response.data);
      });
  }, [page, perPage]);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangePerPage = useCallback(event => {
    setPerPage(event.target.value);
    setPage(0);
  }, []);

  const dataRows = useMemo(() => {
    return expenses.data;
  }, [expenses]);

  return (
    <Container>
      <PageTitle>
        <Typography component="h1" variant="h5">
          Todas as despesas
        </Typography>
      </PageTitle>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Operação</TableCell>
              <TableCell>Urgente?</TableCell>
              <TableCell>Pago?</TableCell>
              <TableCell>Despesa</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Operador</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows.map(row => (
              <TableRow
                key={row.id}
                hover
                component={Link}
                to={`/expenses/show/${row.id}`}
                style={{ textDecoration: 'none' }}
              >
                <TableCell align="center">
                  <Link to={`/operations/show/${row.operation_id}`}>
                    <RvHookupIcon />
                  </Link>
                </TableCell>
                <TableCell>{row.is_urgent ? 'Sim' : 'Não'}</TableCell>
                <TableCell>{row.is_paid ? 'Sim' : 'Não'}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{parseCurrency(row.value)}</TableCell>
                <TableCell>{row.operation.requester.name}</TableCell>
                <TableCell>{row.user.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              {expenses.data.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[10, 15, 25, 50, 100]}
                  count={expenses.total}
                  rowsPerPage={perPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangePerPage}
                  labelRowsPerPage="linhas por página"
                />
              )}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ListExpenses;
