import React from 'react';
import { Star } from '@material-ui/icons';

interface ShowRateStarProps {
  rate: number;
}

const ShowRateStar: React.FC<ShowRateStarProps> = ({ rate }) => (
  <span
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 16,
    }}
  >
    <Star htmlColor="#ffb400" />
    <strong style={{ color: '#333' }}>{rate}</strong>
  </span>
);

export default React.memo(ShowRateStar);
