import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import StarBorderIcon from '@material-ui/icons/SubdirectoryArrowRightOutlined';
import DashboardIcon from '@material-ui/icons/Dashboard';
import StarRateIcon from '@material-ui/icons/StarRate';
import RvHookupIcon from '@material-ui/icons/RvHookup';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import PeopleIcon from '@material-ui/icons/People';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Link, SubItem, Collapse } from './styles';

export const MainListItems: React.FC = () => {
  const [collapseMenu, setCollapseMenu] = React.useState({
    quickMenu: true,
    clients: false,
    motorists: false,
    freights: false,
  });

  const handleQuickMenu = React.useCallback(() => {
    setCollapseMenu(state => ({ ...state, quickMenu: !state.quickMenu }));
  }, []);

  const handleClients = React.useCallback(() => {
    setCollapseMenu(state => ({ ...state, clients: !state.clients }));
  }, []);

  const handleMotorists = React.useCallback(() => {
    setCollapseMenu(state => ({ ...state, motorists: !state.motorists }));
  }, []);

  const handleFreights = React.useCallback(() => {
    setCollapseMenu(state => ({ ...state, freights: !state.freights }));
  }, []);

  return (
    <>
      <Link to="/dashboard" activeClassName="active">
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Início" />
        </ListItem>
      </Link>
      <ListItem button onClick={handleQuickMenu}>
        <ListItemIcon>
          <StarRateIcon />
        </ListItemIcon>
        <ListItemText primary="Menu Rápido" />
        {collapseMenu.quickMenu ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={collapseMenu.quickMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/motorists/create" activeClassName="active">
            <SubItem button>
              <ListItemIcon>
                <StarBorderIcon />
              </ListItemIcon>
              <ListItemText primary="Cadastro de motorista" />
            </SubItem>
          </Link>
          <Link to="/clients/create" activeClassName="active">
            <SubItem button>
              <ListItemIcon>
                <StarBorderIcon />
              </ListItemIcon>
              <ListItemText primary="Cadastro de cliente" />
            </SubItem>
          </Link>
          <Link to="/freights/create" activeClassName="active">
            <SubItem button>
              <ListItemIcon>
                <StarBorderIcon />
              </ListItemIcon>
              <ListItemText primary="Cadastro de frete" />
            </SubItem>
          </Link>
          <Link to="/freights/list" activeClassName="active">
            <SubItem button>
              <ListItemIcon>
                <StarBorderIcon />
              </ListItemIcon>
              <ListItemText primary="Lista de fretes" />
            </SubItem>
          </Link>
          {/* <Link to="/agenciator/create" activeClassName="active">
            <SubItem button>
              <ListItemIcon>
                <StarBorderIcon />
              </ListItemIcon>
              <ListItemText primary="Cadastro de agenciador" />
            </SubItem>
          </Link> */}
        </List>
      </Collapse>

      <ListItem button onClick={handleClients}>
        <ListItemIcon>
          <BusinessCenterIcon />
        </ListItemIcon>
        <ListItemText primary="Clientes" />
        {collapseMenu.clients ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={collapseMenu.clients} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/clients/create" activeClassName="active">
            <SubItem button>
              <ListItemIcon>
                <StarBorderIcon />
              </ListItemIcon>
              <ListItemText primary="Cadastro" />
            </SubItem>
          </Link>
          <Link to="/clients/list" activeClassName="active">
            <SubItem button>
              <ListItemIcon>
                <StarBorderIcon />
              </ListItemIcon>
              <ListItemText primary="Base de Clientes" />
            </SubItem>
          </Link>
        </List>
      </Collapse>

      <ListItem button onClick={handleMotorists}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Motoristas" />
        {collapseMenu.motorists ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={collapseMenu.motorists} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/motorists/list" activeClassName="active">
            <SubItem button>
              <ListItemIcon>
                <StarBorderIcon />
              </ListItemIcon>
              <ListItemText primary="Todos os motoristas" />
            </SubItem>
          </Link>
          <Link to="/motorists/create" activeClassName="active">
            <SubItem button>
              <ListItemIcon>
                <StarBorderIcon />
              </ListItemIcon>
              <ListItemText primary="Cadastro" />
            </SubItem>
          </Link>

          <Link to="/motorists/completed" activeClassName="active">
            <SubItem button>
              <ListItemIcon>
                <StarBorderIcon />
              </ListItemIcon>
              <ListItemText primary="Completos" />
            </SubItem>
          </Link>

          <Link to="/motorists/spotx" activeClassName="active">
            <SubItem button>
              <ListItemIcon>
                <StarBorderIcon />
              </ListItemIcon>
              <ListItemText primary="SpotX" />
            </SubItem>
          </Link>
        </List>
      </Collapse>

      <ListItem button onClick={handleFreights}>
        <ListItemIcon>
          <RvHookupIcon />
        </ListItemIcon>
        <ListItemText primary="Fretes" />
        {collapseMenu.freights ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={collapseMenu.freights} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/freights/create" activeClassName="active">
            <SubItem button>
              <ListItemIcon>
                <StarBorderIcon />
              </ListItemIcon>
              <ListItemText primary="Cadastro" />
            </SubItem>
          </Link>
          <Link to="/freights/list" activeClassName="active">
            <SubItem button>
              <ListItemIcon>
                <StarBorderIcon />
              </ListItemIcon>
              <ListItemText primary="Lista de fretes" />
            </SubItem>
          </Link>
          <Link to="/freights/disabled" activeClassName="active">
            <SubItem button>
              <ListItemIcon>
                <StarBorderIcon />
              </ListItemIcon>
              <ListItemText primary="Desativados" />
            </SubItem>
          </Link>
        </List>
      </Collapse>
    </>
  );
};
