import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { AxiosError } from 'axios';
import { ItemType } from '../../../../services/dtos/types';
import Input from '../../../../components/Input';
import Radio from '../../../../components/Radio';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import getValidationErrors from '../../../../utils/getValidationErrors';
import InputNumberMask from '../../../../components/InputNumberMask';
import parseCurrency from '../../../../utils/parseCurrency';

interface FreightItemProps {
  item?: ItemType;
}

const FreightItem: React.FC<FreightItemProps> = ({ item }) => {
  const formRef = React.useRef<FormHandles>(null);
  const [editMode, setEditMode] = React.useState(false);
  const { addToast } = useToast();

  const handleSubmit = React.useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Campo obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put(`/freights/items/${item?.id}`, data);
        Object.assign(item, response.data);

        addToast({
          type: 'success',
          title: 'Carga alterada com sucesso!',
        });
        setEditMode(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const error = err as AxiosError;
          addToast({
            type: 'error',
            title:
              error.response?.data.message ||
              'Não foi possível alterar a carga. Tente novamente mais tarde',
          });
        }
      }
    },
    [addToast, item],
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmit} noValidate initialData={item}>
      <Table>
        <TableBody>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Carga
            </TableCell>
            <TableCell>
              {editMode ? (
                <Input name="name" label="Produdo (carga)" />
              ) : (
                item?.name
              )}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Observações
            </TableCell>
            <TableCell>
              {editMode ? <Input name="description" /> : item?.description}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Categoria
            </TableCell>
            <TableCell>{item?.category?.name}</TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Exige rastreamento?
            </TableCell>
            <TableCell>
              {!editMode && (item?.require_tracker ? 'Sim' : 'Não')}
              {editMode && (
                <Radio
                  row
                  name="require_tracker"
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                />
              )}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Complemento?
            </TableCell>
            <TableCell>
              {!editMode && (item?.has_complement ? 'Sim' : 'Não')}
              {editMode && (
                <Radio
                  row
                  name="has_complement"
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                />
              )}
            </TableCell>
          </TableRow>
          {item?.category?.name === 'Unidades' && (
            <TableRow hover>
              <TableCell component="th" variant="head" scope="row">
                Dimensões
              </TableCell>
              <TableCell>
                {!editMode &&
                  `L: ${item?.width} x A: ${item?.height} x P: ${item?.depth}`}
                {editMode && (
                  <div style={{ display: 'flex' }}>
                    <Input name="width" type="number" label="Largura" />
                    <Input name="height" type="number" label="Altura" />
                    <Input name="depth" type="number" label="Profundidade" />
                  </div>
                )}
              </TableCell>
            </TableRow>
          )}
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Peso (Kg)
            </TableCell>
            <TableCell>
              {editMode ? (
                <Input name="weight" type="number" label="Peso" />
              ) : (
                item?.weight
              )}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell component="th" variant="head" scope="row">
              Valor
            </TableCell>
            <TableCell>
              {editMode ? (
                <InputNumberMask
                  name="value"
                  label="Valor do produto"
                  numberFormat={{ prefix: 'R$ ' }}
                />
              ) : (
                parseCurrency(item?.value)
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}
      >
        {!editMode && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setEditMode(true)}
          >
            Editar
          </Button>
        )}
        {editMode && (
          <div style={{ marginLeft: 16 }}>
            <Button color="secondary" onClick={() => setEditMode(false)}>
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: 16 }}
            >
              Salvar
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

export default FreightItem;
