import { Box, Grid, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Form } from '@unform/web';

export const Container = styled(Form)({
  marginBottom: 24,
  padding: 16,
  border: '1px solid #ccc',
  backgroundColor: '#fff',
  borderRadius: 4,
});

export const Title = styled(Typography)({
  marginBottom: 8,
  fontWeight: 500,
  color: '#999',
});

export const Content = styled(Grid)({
  // marginBottom: 8,
});

export const Footer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 8,
});
