/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
} from 'date-fns';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { LinkRounded, Search } from '@material-ui/icons';
import { Form } from '@unform/web';

import {
  MotoristType,
  PaginateListType,
} from '../../../../services/dtos/types';
import api from '../../../../services/api';
import ShowRateStar from '../../../../components/ShowRateStar';
import LinkMotoristToFreight, {
  LinkMotoristToFreightHandles,
} from './LinkMotoristToFreight';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';

import getAllDDDs from '../../../../utils/getAllDDDs';
import MultipleSelect from '../../../../components/MultipleSelect';

interface CompatibleMotoristsProps {
  freight_id: string;
  is_fretebras: boolean;
}

interface MotoristsCheckedCalls {
  motorist_id: string;
  checked: boolean;
}

const CompatibleMotorists: React.FC<CompatibleMotoristsProps> = ({
  freight_id,
  is_fretebras,
}) => {
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(15);
  const [motoristName, setMotoristName] = React.useState<string>();
  const [ddds, setDDDs] = React.useState<number[]>([]);
  const linkMotoristRef = React.useRef<LinkMotoristToFreightHandles>(null);

  const history = useHistory();
  const { search } = useLocation();
  const [radius, setRadius] = React.useState(() => {
    const query = new URLSearchParams(search);
    const radiusValue = query.get('radius');
    if (radiusValue) return Number(radiusValue);
    return 80;
  });

  const [motorists, setMotorists] = React.useState<
    PaginateListType<MotoristType>
  >({
    current_page: 0,
    per_page: 15,
    from: 0,
    to: 0,
    total: 0,
    data: [],
  });

  const [motoristsCheckedCalls, setMotoristsCheckedCalls] = React.useState<
    MotoristsCheckedCalls[]
  >([]);

  React.useEffect(() => {
    const loadMotorists = async () => {
      if (freight_id) {
        const response = await api.get('/freights/list-compatible-motorists', {
          params: {
            freight_id,
            page: page + 1,
            per_page: perPage,
            name: motoristName,
            ddds,
            radius,
          },
        });

        setMotorists(response.data);
      }
    };

    loadMotorists();
  }, [freight_id, page, perPage, motoristName, radius, ddds]);

  React.useEffect(() => {
    const loadCheckedMotorists = async () => {
      if (motorists.data.length > 0 && freight_id) {
        const motorist_ids = motorists.data.map(item => item.id);

        const response = await api.post('/freights/check-motorist-calls/list', {
          motorist_ids,
          freight_id,
        });

        setMotoristsCheckedCalls(response.data);
      }
    };

    loadCheckedMotorists();
  }, [motorists, freight_id]);

  const handleChangePage = React.useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangePerPage = React.useCallback(event => {
    setPerPage(event.target.value);
    setPage(0);
  }, []);

  const handleLinkMotoristToFreight = React.useCallback(motorist => {
    linkMotoristRef.current?.setMotorist(motorist);
    linkMotoristRef.current?.toggleDialog(true);
  }, []);

  const getCheckinTime = React.useCallback((date: Date) => {
    const nowDate = new Date();
    const parsedDate = new Date(date);

    const diffTimeMinutes = differenceInMinutes(nowDate, parsedDate);

    if (diffTimeMinutes < 60) return `Há ${diffTimeMinutes} min`;
    if (diffTimeMinutes >= 60 && diffTimeMinutes < 120) return 'Há 1 hora';

    const diffTimeHours = differenceInHours(nowDate, parsedDate);

    if (diffTimeHours > 1 && diffTimeHours < 48)
      return `Há ${diffTimeHours} horas`;

    const diffTimeDays = differenceInDays(nowDate, parsedDate);

    return `Há ${diffTimeDays} dias`;
  }, []);

  const handleFilterMotorists = React.useCallback(
    data => {
      setMotoristName(data.name);
      setRadius(data.radius);
      setDDDs(data.ddds.map((i: { value: string }) => i.value));
      history.push({ search: `?radius=${data.radius}` });
    },
    [history],
  );

  const handleToggleCheckMotoristCall = React.useCallback(
    async (motorist_id: string, checked: boolean) => {
      if (freight_id) {
        if (checked) {
          await api.post('/freights/check-motorist-calls', {
            motorist_id,
            freight_id,
          });

          const updatedMotoristCalls = motoristsCheckedCalls.map(item => {
            if (item.motorist_id === motorist_id) {
              item.checked = true;
            }
            return item;
          });

          setMotoristsCheckedCalls(updatedMotoristCalls);
        } else {
          await api.delete(
            `/freights/check-motorist-calls/${motorist_id}/${freight_id}`,
          );

          const updatedMotoristCalls = motoristsCheckedCalls.map(item => {
            if (item.motorist_id === motorist_id) {
              item.checked = false;
            }
            return item;
          });

          setMotoristsCheckedCalls(updatedMotoristCalls);
        }
      }
    },
    [freight_id, motoristsCheckedCalls],
  );

  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell colSpan={8}>
            <Form onSubmit={handleFilterMotorists} style={{ display: 'flex' }}>
              <Input name="name" label="Nome do motorista" size="small" />
              <MultipleSelect
                name="ddds"
                label="DDDs"
                size="small"
                options={getAllDDDs.map(item => ({
                  label: String(item.ddd),
                  value: item.ddd,
                }))}
                style={{ width: 270, marginLeft: 12 }}
              />
              <Select
                name="radius"
                label="Raio"
                size="small"
                options={[
                  { label: '10 Km', value: 10 },
                  { label: '30 Km', value: 30 },
                  { label: '50 Km', value: 50 },
                  { label: '80 Km', value: 80 },
                  { label: '100 Km', value: 100 },
                ]}
                defaultValue={radius}
                style={{ width: 180, marginLeft: 12 }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginLeft: 15 }}
              >
                <Search />
              </Button>
            </Form>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Nome</TableCell>
          <TableCell>Telefone</TableCell>
          <TableCell>Ligou?</TableCell>
          <TableCell>Origem</TableCell>
          <TableCell>Destino</TableCell>
          <TableCell>Checkin</TableCell>
          <TableCell align="center">Veículo</TableCell>
          <TableCell>Vincular</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {motorists &&
          motorists.data.map(motorist => {
            const checkedCall = motoristsCheckedCalls.find(
              item => item.motorist_id === motorist.id,
            );
            const checked = !!checkedCall?.checked;
            return (
              <TableRow key={motorist.id} hover>
                <TableCell>
                  <Link
                    to={`/motorists/show/${motorist.id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {motorist.rate_score && (
                        <ShowRateStar rate={motorist.rate_score} />
                      )}
                      {motorist.name}
                      {+motorist.type === 2 && ' (PJ)'}
                    </div>
                  </Link>
                </TableCell>
                <TableCell>{motorist.phone}</TableCell>
                <TableCell>
                  <input
                    type="checkbox"
                    checked={checked}
                    onClick={() =>
                      handleToggleCheckMotoristCall(motorist.id, !checked)
                    }
                  />
                </TableCell>
                <TableCell>
                  {motorist.routes?.map(item => (
                    <div key={item.id}>{item.origin_city.name}</div>
                  ))}
                </TableCell>
                <TableCell>
                  {motorist.routes?.map(item => (
                    <div key={item.id}>{item.destination_city.name}</div>
                  ))}
                </TableCell>
                <TableCell>
                  {motorist.checkins?.map(item => (
                    <div key={item.id}>
                      <div>
                        <strong>{item.city.name}</strong>
                      </div>
                      <span>{getCheckinTime(item.checkin_at)}</span>
                    </div>
                  ))}
                </TableCell>
                <TableCell align="center">
                  {motorist.vehicle?.vehicle_category?.name}
                  <span>/</span>
                  {motorist.vehicle?.vehicle_body?.name}
                  <div>{motorist.vehicle?.release_year}</div>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      handleLinkMotoristToFreight(motorist);
                    }}
                  >
                    <LinkRounded />
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
      <TableFooter>
        <TableRow>
          {motorists.data.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 15, 50, 100, 200, 500]}
              count={motorists.total}
              rowsPerPage={perPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangePerPage}
              labelRowsPerPage="linhas por página"
            />
          )}
        </TableRow>
      </TableFooter>
      <LinkMotoristToFreight
        ref={linkMotoristRef}
        freight_id={freight_id}
        is_fretebras={is_fretebras}
      />
    </Table>
  );
};

export default CompatibleMotorists;
