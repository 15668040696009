import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { Divider, List } from '@material-ui/core';
import { useStyles } from './styles';
import { MainListItems } from './ListItemsMenu';

import logoImg from '../../assets/logo.png';
import { useLayout } from '..';

const SideMenu: React.FC = () => {
  const classes = useStyles();
  const { openSideMenu, toggleOpenSideMenu } = useLayout();

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(
          classes.drawerPaper,
          !openSideMenu && classes.drawerPaperClose,
        ),
      }}
      open={openSideMenu}
    >
      <div className={classes.toolbarHeader}>
        <img src={logoImg} alt="SpotX Logo" width="80" />
        <IconButton onClick={toggleOpenSideMenu}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <MainListItems />
      </List>
    </Drawer>
  );
};

export default SideMenu;
