import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { useField } from '@unform/core';

interface Option {
  label: string;
  value: string | number;
}

type AppMultipleSelectProps = TextFieldProps & {
  name: string;
  options: Option[];
  max?: number;
};

const MultipleSelect: React.FC<AppMultipleSelectProps> = ({
  name,
  options,
  max,
  variant = 'outlined',
  ...props
}) => {
  const [selectedOptions, setSelectedOptions] = React.useState<Option[]>([]);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [helperText, setHelperText] = React.useState(error);

  React.useEffect(() => {
    registerField({
      name: fieldName,
      getValue() {
        if (selectedOptions.length > 0) {
          return selectedOptions;
        }
        return undefined;
      },
    });
  }, [registerField, fieldName, selectedOptions]);
  return (
    <div>
      <Autocomplete
        multiple
        limitTags={2}
        options={options}
        getOptionLabel={option => option.label}
        defaultValue={defaultValue}
        renderInput={params => (
          <TextField
            name={name}
            {...params}
            {...props}
            variant={variant}
            helperText={helperText || error}
            error={!!helperText || !!error}
          />
        )}
        onChange={(event, value) => {
          setHelperText(undefined);
          if (max && value.length > max) {
            setHelperText(`Você deve escolher no máximo ${max} itens`);
          } else {
            setSelectedOptions(value);
          }
        }}
      />
    </div>
  );
};

export default MultipleSelect;
