import React from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../services/api';
import { ClientType } from '../../../services/dtos/types';
import Address from './Address';
import CompanyData from './CompanyData';

import {
  Container,
  PageTitle,
  Section,
  SectionTitle,
  SectionContent,
} from './styles';

interface RouteParams {
  id: string;
}

const ShowClient: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const [client, setClient] = React.useState({} as ClientType);

  React.useEffect(() => {
    document.title = 'SpotX - Informações do cliente';
  }, []);

  React.useEffect(() => {
    const loadMotorist = async () => {
      const response = await api.get(`/clients/${id}`);
      setClient(response.data);
    };

    loadMotorist();
  }, [id]);
  return (
    <Container>
      <PageTitle variant="h5">Informações do cliente</PageTitle>

      <Section>
        <SectionTitle color="textSecondary">Dados da empresa</SectionTitle>
        <SectionContent>
          <CompanyData
            companyData={{
              id: client.id,
              cnpj: client.cnpj,
              name: client.name,
              main_activity: client.main_activity,
              email: client.email,
              nickname: client.nickname,
              phone: client.phone,
            }}
          />
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle color="textSecondary">Endereço</SectionTitle>
        <SectionContent>
          <Address
            address={{
              id: client.id,
              address: client.address,
              address_number: Number(client.address_number),
              district: client.district,
              city: client.city,
              zipcode: client.zipcode,
              state: client.state,
            }}
          />
        </SectionContent>
      </Section>
    </Container>
  );
};

export default ShowClient;
