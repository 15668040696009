import { Button } from '@material-ui/core';
import { WhereToVote } from '@material-ui/icons';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';

import api from '../../../services/api';
import { FreightType } from '../../../services/dtos/types';
import Attachments from './Attachments';
import CompatibleMotorists from './CompatibleMotorists';
import FreightData from './FreightData';
import FreightItem from './FreightItem';
import MapLocation from './MapLocation';
import RateFreight, { RateFreightHandles } from './RateFreight';
import SelectedMotorist from './SelectedMotorist';

import {
  Container,
  PageTitle,
  Section,
  SectionTitle,
  SectionContent,
} from './styles';

interface RequestParams {
  id: string;
}

const ShowFreight: React.FC = () => {
  const { id } = useParams<RequestParams>();
  const [freight, setFreight] = React.useState<FreightType | undefined>();
  const rateFreightRef = React.useRef<RateFreightHandles>(null);
  const { addToast } = useToast();

  React.useEffect(() => {
    document.title = 'SpotX - Informações do frete';
  }, []);

  React.useEffect(() => {
    const loadFreight = async () => {
      const response = await api.get<FreightType>(`/freights/${id}`);
      setFreight(response.data);
    };

    loadFreight();
  }, [id]);

  const handleFinishFreight = React.useCallback(() => {
    rateFreightRef.current?.toggleDialog(true);
  }, []);

  const handleDisableFreight = React.useCallback(
    async (freight_id: string) => {
      await api.put(`/freights/${freight_id}/status`, { status: 'disabled' });
      await api.patch(`/freights/${freight_id}/remove-fretebras-id`);

      addToast({
        type: 'success',
        title: 'Frete desativado com sucesso!',
      });

      setFreight(state => ({ ...state, status: 'disabled' } as FreightType));
    },
    [addToast],
  );

  const handleEnableFreight = React.useCallback(
    async (freight_id: string) => {
      await api.put(`/freights/${freight_id}/status`, { status: 'pending' });

      addToast({
        type: 'success',
        title: 'Frete ativado com sucesso!',
      });

      setFreight(state => ({ ...state, status: 'pending' } as FreightType));
    },
    [addToast],
  );

  return freight ? (
    <Container>
      <PageTitle>
        <span>Informações do frete</span>
        {freight.motorist_id && freight.status === 'delivered' && (
          <>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleFinishFreight}
            >
              Finalizar Frete
              <WhereToVote />
            </Button>
            <RateFreight
              ref={rateFreightRef}
              freight_id={freight.id}
              motorist_id={freight.motorist_id}
            />
          </>
        )}

        {freight.status === 'pending' && (
          <>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleDisableFreight(freight.id)}
            >
              Desativar Frete
            </Button>
          </>
        )}

        {freight.status === 'disabled' && (
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEnableFreight(freight.id)}
            >
              Ativar Frete
            </Button>
          </>
        )}
      </PageTitle>

      <Section>
        <SectionTitle color="textSecondary">Mapa</SectionTitle>
        <SectionContent>
          <MapLocation
            origin={freight.origin}
            destination={freight.destination}
          />
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle color="textSecondary">Dados do frete</SectionTitle>
        <SectionContent>
          <FreightData freight={freight} />
        </SectionContent>
      </Section>

      {!freight.motorist_id ? (
        <Section>
          <SectionTitle color="textSecondary">
            Motoristas compatíveis
          </SectionTitle>
          <SectionContent>
            <CompatibleMotorists
              freight_id={freight.id}
              is_fretebras={!!freight.fretebras_freight_id}
            />
          </SectionContent>
        </Section>
      ) : (
        <Section>
          <SectionTitle color="textSecondary">Motorista vinculado</SectionTitle>
          <SectionContent>
            <SelectedMotorist
              freight_id={freight.id}
              freight_status={freight.status}
              motorist={freight.motorist}
              vehicle={freight.vehicle}
            />
          </SectionContent>
        </Section>
      )}

      <Section>
        <SectionTitle color="textSecondary">Informações da carga</SectionTitle>
        <SectionContent>
          <FreightItem item={freight.item} />
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle color="textSecondary">Anexos</SectionTitle>
        <SectionContent>
          <Attachments freight_id={id} attachments={freight.attachments} />
        </SectionContent>
      </Section>
    </Container>
  ) : (
    <h1>Carregando...</h1>
  );
};

export default ShowFreight;
