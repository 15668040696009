/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { useField } from '@unform/core';

type AppInputProps = TextFieldProps & {
  name: string;
};

const Input: React.FC<AppInputProps> = ({
  name,
  helperText,
  fullWidth = true,
  variant = 'outlined',
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      // path: 'value',
      getValue(ref: HTMLInputElement) {
        if (ref.value && ref.value.length > 0) {
          return ref.value;
        }
        return undefined;
      },
      setValue(ref: HTMLInputElement, value: any) {
        if (value && value.length > 0) {
          ref.value = value;
        }
      },
    });
  }, [registerField, fieldName]);

  return (
    <TextField
      style={{ backgroundColor: '#fff' }}
      inputRef={inputRef}
      name={name}
      defaultValue={defaultValue}
      error={!!error}
      helperText={error || helperText}
      fullWidth={fullWidth}
      variant={variant}
      {...props}
    />
  );
};

export default React.memo(Input);
