/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  Button,
} from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import { Link, useLocation } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import api from '../../../services/api';
import {
  CityType,
  MotoristType,
  PaginateListType,
} from '../../../services/dtos/types';
import { Container, PageTitle } from './styles';
import Filter from './Filter';
import ShowRateStar from '../../../components/ShowRateStar';

interface FilterProps {
  type: number;
  name?: string;
  vehicle_categories?: string;
  vehicle_bodies?: string;
  origin?: CityType;
  destination?: CityType;
  origin_radius?: number;
  destination_radius?: number;
  checkin?: CityType;
}

interface FilterParams {
  spotx?: boolean;
  type?: number;
  name?: string;
  vehicle_categories?: string[];
  vehicle_bodies?: string[];
  origins?: number[];
  destinations?: number[];
  checkins?: number[];
}

const ListMotorists: React.FC = () => {
  const filterRef = React.useRef<FormHandles>(null);

  const [motorists, setMotorists] = useState<PaginateListType<MotoristType>>({
    current_page: 0,
    from: 1,
    to: 15,
    per_page: 15,
    total: 15,
    data: [],
  } as PaginateListType<MotoristType>);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [filters, setFilters] = useState<FilterParams>({} as FilterParams);
  const [spotx, setSpotx] = useState(false);
  const location = useLocation();

  React.useEffect(() => {
    document.title = 'SpotX - Todos os motoristas';
    if (location.pathname === '/motorists/spotx') {
      setSpotx(true);
      setFilters(state => ({ ...state, spotx: true }));
      document.title = 'SpotX - Motoristas SpotX';
    }
  }, [location]);

  useEffect(() => {
    api
      .get('motorists', {
        params: { page: page + 1, per_page: perPage, ...filters },
      })
      .then(response => {
        setMotorists(response.data);
      });
  }, [page, perPage, filters]);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangePerPage = useCallback(event => {
    setPerPage(event.target.value);
    setPage(0);
  }, []);

  const getCityIds = useCallback(
    async (
      origin?: CityType,
      radius?: number,
    ): Promise<number[] | undefined> => {
      if (!origin) return undefined;

      const response = await api.get<CityType[]>('/cities/search/geo', {
        params: {
          lat: origin.lat,
          lng: origin.lng,
          radius,
        },
      });

      return response.data.map(city => {
        return city.ibge_id;
      });
    },
    [],
  );

  const handleFilter = useCallback(async () => {
    const {
      type,
      name,
      vehicle_bodies,
      vehicle_categories,
      origin,
      destination,
      origin_radius,
      destination_radius,
      checkin,
    } = filterRef.current?.getData() as FilterProps;

    const origins = await getCityIds(origin, origin_radius);
    const destinations = await getCityIds(destination, destination_radius);
    const checkins = await getCityIds(checkin, 40);
    const categories = vehicle_categories ? [vehicle_categories] : undefined;
    const bodies = vehicle_bodies ? [vehicle_bodies] : undefined;

    setFilters({
      spotx: spotx || undefined,
      type: type !== 0 ? type : undefined,
      name,
      vehicle_categories: categories,
      vehicle_bodies: bodies,
      origins,
      destinations,
      checkins,
    });
  }, [spotx, getCityIds]);

  const dataRows = useMemo(() => {
    return motorists.data;
  }, [motorists]);

  return (
    <Container>
      <PageTitle>
        <Typography component="h1" variant="h5">
          Motoristas
        </Typography>
        <Button
          component={Link}
          to="/motorists/create"
          variant="contained"
          color="primary"
        >
          Novo motorista
          <PersonAddIcon />
        </Button>
      </PageTitle>

      <Filter
        ref={filterRef}
        renderAction={() => (
          <Button color="primary" variant="contained" onClick={handleFilter}>
            Filtrar
          </Button>
        )}
      />

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell align="right">Telefone</TableCell>
              <TableCell align="right">Cidade</TableCell>
              <TableCell align="right">Origem</TableCell>
              <TableCell align="right">Destino</TableCell>
              <TableCell align="right">Veículo</TableCell>
              <TableCell align="right">Carroceria</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows.map(row => (
              <TableRow
                key={row.id}
                component={Link}
                to={`/motorists/show/${row.id}`}
                hover
                style={{ textDecoration: 'none' }}
              >
                <TableCell component="th" scope="row" align="center">
                  {row.rate_score && <ShowRateStar rate={row.rate_score} />}
                  {!row.rate_score && <WarningIcon htmlColor="#ff9800" />}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                  {+row.type === 2 && ' (PJ)'}
                </TableCell>
                <TableCell align="right">{row.phone}</TableCell>
                <TableCell align="right">
                  {row.checkins &&
                    row.checkins.length > 0 &&
                    row.checkins[0].city.name}
                </TableCell>
                <TableCell align="right">
                  {row.routes?.map(route => (
                    <div key={route.id}>{route.origin_city.name}</div>
                  ))}
                </TableCell>
                <TableCell align="right">
                  {row.routes?.map(route => (
                    <div key={route.id}>{route.destination_city.name}</div>
                  ))}
                </TableCell>
                <TableCell align="right">
                  {row.vehicle?.vehicle_category?.name}
                </TableCell>
                <TableCell align="right">
                  {row.vehicle?.vehicle_body?.name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              {motorists.data.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[1, 10, 15, 25, 50, 100]}
                  count={motorists.total}
                  rowsPerPage={perPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangePerPage}
                  labelRowsPerPage="linhas por página"
                />
              )}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ListMotorists;
