import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  Tabs,
  Tab,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import { format, utcToZonedTime } from 'date-fns-tz';
import api from '../../../services/api';
import { FreightType, PaginateListType } from '../../../services/dtos/types';
import { Container, PageTitle } from './styles';

const ListFreights: React.FC = () => {
  const [tab, setTab] = React.useState(0);
  const [freights, setFreights] = useState<PaginateListType<FreightType>>({
    current_page: 0,
    from: 1,
    to: 15,
    per_page: 15,
    total: 15,
    data: [],
  } as PaginateListType<FreightType>);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(15);

  React.useEffect(() => {
    document.title = 'SpotX - Todos os Fretes';
  }, []);

  useEffect(() => {
    let status: string | string[] = [
      'in_origin',
      'collecting',
      'ready',
      'on_road',
      'in_destination',
      'delivered',
    ];
    if (tab === 0) status = 'pending';
    if (tab === 2) status = 'disabled';
    if (tab === 3) status = 'finished';
    api
      .get('freights', {
        params: {
          page: page + 1,
          per_page: perPage,
          status,
        },
      })
      .then(response => {
        setFreights(response.data);
      });
  }, [page, perPage, tab]);

  const getStatus = useCallback(status => {
    if (status === 'in_origin') return 'Indo para coleta';
    if (status === 'collecting') return 'Pronto para carregar';
    if (status === 'ready') return 'Carregado';
    if (status === 'on_road') return 'Em viagem';
    if (status === 'in_destination') return 'No destino';
    if (status === 'delivered') return 'Descarregado';
    return '--';
  }, []);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const handleChangeTablePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsTablePerPage = useCallback(event => {
    setPerPage(event.target.value);
    setPage(0);
  }, []);

  const getCreatedAt = useCallback((date: string) => {
    const parsedDate = utcToZonedTime(date, 'America/Sao_Paulo');

    return format(parsedDate, "dd/MM/yyyy 'às' HH:mm");
  }, []);

  return (
    <Container>
      <PageTitle>
        <Paper square>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTab}
          >
            <Tab label="Fretes Ativos" />
            <Tab label="Em Andamento" />
            <Tab label="Desativados" />
            <Tab label="Finalizados" />
          </Tabs>
        </Paper>
      </PageTitle>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {tab === 1 && <TableCell>Status</TableCell>}
              <TableCell>Cliente</TableCell>
              <TableCell>Origem</TableCell>
              <TableCell>Destino</TableCell>

              {tab > 0 && (
                <>
                  <TableCell>Motorista</TableCell>
                  <TableCell>Veículo</TableCell>
                </>
              )}

              <TableCell>Produto</TableCell>
              <TableCell>valor</TableCell>
              <TableCell>Cadastrado em</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {freights.data.map(row => (
              <TableRow
                key={row.id}
                component={Link}
                to={`/freights/show/${row.id}`}
                hover
                style={{ textDecoration: 'none' }}
              >
                {tab === 1 && <TableCell>{getStatus(row.status)}</TableCell>}
                <TableCell component="th" scope="row">
                  {row.requester.nickname}
                </TableCell>
                <TableCell component="th" scope="row">
                  {`${row.origin.name}-${row.origin.state.uf}`}
                </TableCell>
                <TableCell>{`${row.destination.name}-${row.destination.state.uf}`}</TableCell>
                {tab > 0 && (
                  <>
                    <TableCell>{row.motorist?.name}</TableCell>
                    <TableCell>{row.vehicle?.vehicle_category?.name}</TableCell>
                  </>
                )}
                <TableCell>{row.item?.name}</TableCell>
                <TableCell>
                  R$
                  {row.suggested_price}
                </TableCell>
                <TableCell>{getCreatedAt(row.created_at)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              {freights.data.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[10, 15, 25, 50, 100]}
                  count={freights.total}
                  rowsPerPage={perPage}
                  page={page}
                  onChangePage={handleChangeTablePage}
                  onChangeRowsPerPage={handleChangeRowsTablePerPage}
                  labelRowsPerPage="linhas por página"
                />
              )}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ListFreights;
