/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';
import {
  InputLabelProps as MuiInputLabelProps,
  TextField,
} from '@material-ui/core';
import MaskedInput, { Props } from 'react-input-mask';
import { useField } from '@unform/core';

type AppInputProps = Props & {
  name: string;
  mask: string | Array<string | RegExp>;
  helperText?: string;
  fullWidth?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  label?: string;
  InputLabelProps?: Partial<MuiInputLabelProps>;
};

const InputMask: React.FC<AppInputProps> = ({
  name,
  mask,
  maskPlaceholder = null,
  helperText,
  fullWidth = true,
  variant = 'outlined',
  label,
  InputLabelProps,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      // path: 'value',
      getValue(ref: HTMLInputElement) {
        if (ref.value && ref.value.length > 0) {
          return ref.value;
        }
        return undefined;
      },
      setValue(ref: HTMLInputElement, value: any) {
        if (value && value.length > 0) {
          ref.value = value;
        }
      },
    });
  }, [registerField, fieldName]);

  return (
    <MaskedInput mask={mask} maskPlaceholder={maskPlaceholder} {...props}>
      <TextField
        style={{ backgroundColor: '#fff' }}
        inputRef={inputRef}
        name={name}
        defaultValue={defaultValue}
        error={!!error}
        helperText={error || helperText}
        fullWidth={fullWidth}
        variant={variant}
        label={label}
        InputLabelProps={InputLabelProps}
      />
    </MaskedInput>
  );
};

export default React.memo(InputMask);
