import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import Dashboard from '../pages/Dashboard';
import { Layout } from '../layout';
import ListMotorists from '../pages/Motorists/List';
import CreateMotorist from '../pages/Motorists/Create';
import CreateClient from '../pages/Clients/Create';
import ShowMotorist from '../pages/Motorists/Show';
import ListClients from '../pages/Clients/List';
import ShowClient from '../pages/Clients/Show';
import ListVehicles from '../pages/Vehicles/List';
import CreateVehicle from '../pages/Vehicles/Create';
import ShowVehicle from '../pages/Vehicles/Show';
import ListFreights from '../pages/Freights/List';
import ShowFreight from '../pages/Freights/Show';
import ListExpenses from '../pages/Expenses/List';
import ShowExpense from '../pages/Expenses/Show';
import CreateFreight from '../pages/Freights/Create';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Layout>
      <Route path="/dashboard" exact component={Dashboard} isPrivate />

      <Route path="/motorists/list" exact component={ListMotorists} isPrivate />
      <Route
        path="/motorists/spotx"
        exact
        component={ListMotorists}
        isPrivate
      />
      <Route
        path="/motorists/create"
        exact
        component={CreateMotorist}
        isPrivate
      />
      <Route
        path="/motorists/show/:id"
        exact
        component={ShowMotorist}
        isPrivate
      />

      <Route path="/clients/list" exact component={ListClients} isPrivate />
      <Route path="/clients/create" exact component={CreateClient} isPrivate />
      <Route path="/clients/show/:id" component={ShowClient} isPrivate />

      <Route path="/vehicles/list" exact component={ListVehicles} isPrivate />
      <Route
        path="/vehicles/create"
        exact
        component={CreateVehicle}
        isPrivate
      />
      <Route
        path="/vehicles/show/:id"
        exact
        component={ShowVehicle}
        isPrivate
      />
      <Route path="/freights/list" exact component={ListFreights} isPrivate />
      <Route
        path="/freights/create"
        exact
        component={CreateFreight}
        isPrivate
      />
      <Route
        path="/freights/show/:id"
        exact
        component={ShowFreight}
        isPrivate
      />

      <Route path="/expenses/list" exact component={ListExpenses} isPrivate />
      <Route
        path="/expenses/show/:id"
        exact
        component={ShowExpense}
        isPrivate
      />
    </Layout>
  </Switch>
);

export default Routes;
