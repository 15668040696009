import { Button, Grid } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles, Scope } from '@unform/core';
import * as Yup from 'yup';
import { v4 } from 'uuid';
import { useHistory } from 'react-router-dom';

import { AxiosError } from 'axios';
import {
  Container,
  PageTitle,
  Section,
  SectionContent,
  SectionDescription,
} from './styles';
import Input from '../../../components/Input';
import getValidationErrors from '../../../utils/getValidationErrors';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import Radio from '../../../components/Radio';
import Autocomplete from '../../../components/Autocomplete';
import { MotoristType } from '../../../services/dtos/types';
import InputMask from '../../../components/InputMask';
import AutocompleteGeo from '../../../components/AutocompleteGeo';

interface OptionType {
  label: string;
  value: string;
}

interface CategoryAndBodyType {
  id: string;
  name: string;
  type: string;
}

interface Route {
  is_recurrent?: boolean;
  frequency?: number;
  frequency_type?: string;
  origin: {
    ibge_id: number;
    name: string;
  };
  destination: {
    ibge_id: number;
    name: string;
  };
}

interface Vehicle {
  license_plate: string;
  vehicle_category_id: string;
  vehicle_body_id: string;
  release_year?: number;
  has_insurance?: boolean;
  has_tracker?: boolean;
}

interface Checkin {
  checkin_at: Date;
  city_id: {
    ibge_id: number;
  };
}

interface AmtRoute {
  id: string;
  is_recurrent: boolean;
}

const currentYear = new Date().getFullYear();
const releaseMinYear = currentYear - 30;

const CreateMotorist: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [amtRoutes, setAmtRoutes] = useState<AmtRoute[]>(() => {
    return [
      {
        id: v4(),
        is_recurrent: false,
      },
    ];
  });
  const [categories, setCategories] = React.useState<OptionType[]>([]);
  const [bodies, setBodies] = React.useState<OptionType[]>([]);
  const history = useHistory();
  const { addToast } = useToast();

  React.useEffect(() => {
    document.title = 'SpotX - Cadastrar motorista';
  }, []);

  React.useEffect(() => {
    const loadCategories = async () => {
      const response = await api.get<CategoryAndBodyType[]>(
        '/vehicles/categories/search',
      );
      const formattedData = response.data.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setCategories(formattedData);
    };

    const loadBodies = async () => {
      const response = await api.get<CategoryAndBodyType[]>(
        '/vehicles/bodies/search',
      );
      const formattedData = response.data.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setBodies(formattedData);
    };

    loadCategories();
    loadBodies();
  }, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Campo obrigatório'),
          phone: Yup.string().required('Campo obrigatório'),
          other_phone: Yup.string(),
          checkin: Yup.object().shape({
            checkin_at: Yup.date(),
            city_id: Yup.string().required('Campo obrigatório'),
          }),
          routes: Yup.array().of(
            Yup.object().shape({
              origin: Yup.string(),
              destination: Yup.string(),
              is_recurrent: Yup.boolean(),
              frequency: Yup.number().when('is_recurrent', {
                is: true,
                then: Yup.number().required('Campo obrigatório'),
                otherwise: Yup.number(),
              }),
              frequency_type: Yup.string().when('is_recurrent', {
                is: true,
                then: Yup.string().required('Escolha uma opção'),
                otherwise: Yup.string(),
              }),
            }),
          ),
          vehicle: Yup.object().shape({
            license_plate: Yup.string().required('Campo obrigatório'),
            vehicle_category_id: Yup.string()
              .uuid()
              .required('Campo obrigatório'),
            vehicle_body_id: Yup.string().uuid().required('Campo obrigatório'),
            release_year: Yup.number()
              .min(releaseMinYear, `Não pode ser menor que ${releaseMinYear}`)
              .max(currentYear, 'Não pode ser maior que o ano atual')
              .required('Campo obrigatório'),
          }),
        });

        await schema.validate(data, { abortEarly: false });

        const motoristRoutes = data.routes as Route[];
        const vehicle = data.vehicle as Vehicle;
        const checkin = data.checkin as Checkin;
        delete data.routes;
        delete data.vehicle;
        delete data.checkin;

        const motorist = await api.post<MotoristType>('/motorists', data);

        await api.post('/motorists/checkins', {
          checkin_at: checkin.checkin_at,
          city_id: checkin.city_id.ibge_id,
          motorist_id: motorist.data.id,
        });

        await api.post('/vehicles', {
          motorist_id: motorist.data.id,
          license_plate: vehicle.license_plate.toUpperCase(),
          vehicle_category_id: vehicle.vehicle_category_id,
          vehicle_body_id: vehicle.vehicle_body_id,
          release_year: vehicle.release_year,
          has_insurance: vehicle.has_insurance,
          has_tracker: vehicle.has_tracker,
        });

        motoristRoutes.forEach(async route => {
          await api.post('/motorists/routes', {
            motorist_id: motorist.data.id,
            origin_id: route.origin.ibge_id,
            destination_id: route.destination.ibge_id,
            is_recurrent: route.is_recurrent,
            frequency: route.frequency,
            frequency_type: route.frequency_type,
          });
        });

        await api.post('/motorists/driver-licenses', {
          motorist_id: motorist.data.id,
        });

        history.push('/motorists/list');

        addToast({
          title: 'Motorista adicionado com sucesso!',
          type: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const error = err as AxiosError;
          addToast({
            type: 'error',
            title:
              error.response?.data.message ||
              'Não foi possível adicionar o motorista. Tente novamente mais tarde',
          });
        }
      }
    },
    [addToast, history],
  );

  const handleAddRoute = useCallback(() => {
    setAmtRoutes(state => [
      ...state,
      {
        id: v4(),
        is_recurrent: false,
      },
    ]);
  }, []);

  const handleRemoveRoute = useCallback(route => {
    setAmtRoutes(state => state.filter(item => item.id !== route.id));
  }, []);

  return (
    <Container>
      <PageTitle variant="h5">Cadastrar Motorista</PageTitle>

      <Form ref={formRef} onSubmit={handleSubmit} noValidate>
        <Section>
          <SectionDescription>Dados pessoais</SectionDescription>
          <SectionContent>
            <Grid container spacing={2}>
              <Grid item sm={8}>
                <Input name="name" label="Nome completo" required />
              </Grid>
              <Grid item sm={4}>
                <InputMask
                  name="phone"
                  mask="(99) 9 9999-9999"
                  label="Telefone principal"
                  required
                />
              </Grid>
              <Grid item sm={4}>
                <InputMask
                  name="other_phone"
                  mask="(99) 9 9999-9999"
                  label="Outro telefone"
                />
              </Grid>
              <Scope path="checkin">
                <Grid item sm={4}>
                  <AutocompleteGeo name="city_id" label="Checkin" required />
                </Grid>
                <Grid item sm={4}>
                  <Input
                    type="datetime-local"
                    name="checkin_at"
                    label="Data/hora do checkin"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Scope>
            </Grid>
          </SectionContent>
        </Section>
        <Section>
          <SectionDescription>Preferência de rotas</SectionDescription>
          <SectionContent>
            {amtRoutes.map((route, index) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 32,
                }}
                key={route.id}
              >
                <div style={{ flex: 1 }}>
                  <Grid container spacing={2}>
                    <Scope path={`routes[${index}]`}>
                      <Grid item sm={6}>
                        <AutocompleteGeo name="origin" label="Rota de origem" />
                      </Grid>
                      <Grid item sm={6}>
                        <AutocompleteGeo
                          name="destination"
                          label="Rota de destino"
                        />
                      </Grid>
                      <Grid item sm={3}>
                        <Radio
                          row
                          name="is_recurrent"
                          label="Esta rota é recorrente?"
                          options={[
                            { label: 'Sim', value: true },
                            { label: 'Não', value: false },
                          ]}
                          onChange={(event, value) => {
                            if (value === 'true') {
                              setAmtRoutes(state =>
                                state.map(item => {
                                  if (item.id === route.id) {
                                    return {
                                      id: item.id,
                                      is_recurrent: true,
                                    };
                                  }
                                  return item;
                                }),
                              );
                            } else {
                              setAmtRoutes(state =>
                                state.map(item => {
                                  if (item.id === route.id) {
                                    return {
                                      id: item.id,
                                      is_recurrent: false,
                                    };
                                  }
                                  return item;
                                }),
                              );
                            }
                          }}
                        />
                      </Grid>
                      {route.is_recurrent && (
                        <>
                          <Grid item sm={3}>
                            <Input
                              name="frequency"
                              type="number"
                              label="Quantas vezes"
                              required
                            />
                          </Grid>

                          <Grid item sm={6}>
                            <Radio
                              row
                              name="frequency_type"
                              label="Frequência"
                              options={[
                                { label: 'Diário', value: 'daily' },
                                { label: 'Semanal', value: 'weekly' },
                                { label: 'Quinzenal', value: 'biweekly' },
                                { label: 'mensal', value: 'monthly' },
                                { label: 'anual', value: 'annually' },
                              ]}
                              required
                            />
                          </Grid>
                        </>
                      )}
                    </Scope>
                  </Grid>
                </div>
                <div>
                  {amtRoutes.length > 1 && index > 0 && (
                    <Button
                      size="small"
                      style={{ marginLeft: 16 }}
                      variant="contained"
                      color="secondary"
                      onClick={() => handleRemoveRoute(route)}
                    >
                      <DeleteOutlineIcon />
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </SectionContent>
          <SectionContent>
            <Button
              size="small"
              style={{ alignSelf: 'center' }}
              variant="contained"
              color="primary"
              onClick={handleAddRoute}
            >
              Adicionar rota
            </Button>
          </SectionContent>
        </Section>

        <Section>
          <SectionDescription>Veículo</SectionDescription>
          <SectionContent>
            <Grid container spacing={2}>
              <Scope path="vehicle">
                <Grid item sm={4} md={4}>
                  <InputMask
                    mask="aaa****"
                    name="license_plate"
                    label="Placa"
                    required
                  />
                </Grid>
                <Grid item sm={4} md={4}>
                  <Autocomplete
                    name="vehicle_category_id"
                    label="Categoria"
                    options={categories}
                    required
                  />
                </Grid>
                <Grid item sm={4} md={4}>
                  <Autocomplete
                    name="vehicle_body_id"
                    label="Carroceria"
                    options={bodies}
                    required
                  />
                </Grid>

                <Grid item sm={4} md={2}>
                  <InputMask
                    mask="9999"
                    name="release_year"
                    label="Ano"
                    required
                  />
                </Grid>
                <Grid item sm={4} md={3}>
                  <Radio
                    row
                    name="has_tracker"
                    label="Tem rastreador?"
                    options={[
                      { label: 'Sim', value: true },
                      { label: 'Não', value: false },
                    ]}
                  />
                </Grid>
              </Scope>
              <Grid item sm={4} md={5}>
                <Radio
                  row
                  name="prospected_by"
                  label="Prospectado"
                  options={[
                    { label: 'Fretebras', value: 'fretebras' },
                    { label: 'Agenciador', value: 'agenciator' },
                    { label: 'Base', value: 'base' },
                  ]}
                />
              </Grid>
            </Grid>
          </SectionContent>
        </Section>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 20,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            Cadastrar
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default CreateMotorist;
