import {
  Box,
  Container as MuContainer,
  Grid,
  Typography,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const Container = styled(MuContainer)({
  display: 'flex',
  flexDirection: 'column',
});

export const PageTitle = styled(Typography)({
  display: 'flex',
  marginBottom: 20,
});

export const Section = styled(Box)({
  display: 'flex',
  marginBottom: 32,
  paddingBottom: 32,
  borderBottom: '1px solid #ddd',
});

export const SectionDescription = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '20%',
  maxWidth: 340,
  paddingRight: 32,
  color: '#666',
});

export const SectionContent = styled(Grid)({
  flex: 1,
});
