/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useField } from '@unform/core';
import { Rating, RatingProps } from '@material-ui/lab';
import { Box, Typography } from '@material-ui/core';

type AppInputStarProps = RatingProps & {
  name: string;
  label?: string;
  helperText?: string;
};

const InputStar: React.FC<AppInputStarProps> = ({
  name,
  label,
  helperText,
  size = 'large',
  ...props
}) => {
  const [score, setScore] = React.useState<number | undefined>();
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue() {
        return score;
      },
      setValue(ref: RatingProps, value: number | undefined) {
        setScore(value);
      },
    });
  }, [registerField, fieldName, score]);

  const initialValue = React.useMemo(() => {
    if (score) return score;
    if (defaultValue) return defaultValue;
    return null;
  }, [score, defaultValue]);

  return (
    <Box component="fieldset" mb={3} borderColor="transparent">
      {label && <Typography component="legend">{label}</Typography>}
      <Rating
        value={initialValue}
        size={size}
        onChange={(event, newValue) => {
          if (newValue) {
            setScore(newValue);
          }
        }}
        {...props}
      />
      {helperText && (
        <Typography component="legend" variant="caption" color="secondary">
          {helperText}
        </Typography>
      )}
      {error && (
        <Typography component="legend" variant="caption" color="secondary">
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default React.memo(InputStar);
